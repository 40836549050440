import {
  Button,
  Form,
  FormGroup,
  OverlayTrigger,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip
} from "react-bootstrap";
import { InputType } from "../Types/InputType";
import {
  allSpecialChars,
  allSpecialCharsForEmail,
  americanDateFormat,
  americanDateFormatYearAndMonth,
  checkFieldIsValid,
  isIOS,
  pascalSentence
} from "../helpers";
import PhoneInputWithCountrySelect, {
  formatPhoneNumberIntl
} from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import { IFieldDataConfig, IFieldDataOptions } from "../Types/FieldDataConfig";
import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useState
} from "react";
import InputMask from "react-input-mask";
import Select from "react-select";
import { isValidIBAN } from "ibantools";
import { getCountryList } from "../Store/SelectOptionsStore";
import { TFormField } from "./WizardCmp";

import NLflag from "../Assets/Images/NL.svg";

export interface IFormControl extends IFieldDataConfig {
  // ref?: (HTMLInputElement | HTMLSelectElement)[];
  isVisible?: boolean;
  ref?: any; //@TODO: Remove and forwardRef deep from formControlGroup
}

// *eslint-disable-next-line react/display-name
// @@Remove * from the line above to ignore the displayName error from ESLint or use the -> VarName.displayName = "DesiredValue" to name it;
// function FormControl(props: { data: IFormControl; ref: any }) {
export const FormControl = forwardRef<
  any,
  {
    data: IFormControl;
    onChange: (field: TFormField, val: string) => void;
    checkFieldVisibility: (field: IFormControl) => boolean;
    setAttrVisibility: (isVisible: boolean) => void;
  }
>(
  (
    props: {
      data: IFormControl;
      onChange: (field: TFormField, val: string) => void;
      checkFieldVisibility: (field: IFormControl) => boolean;
      setAttrVisibility: (isVisible: boolean) => void;
    },
    ref: any
  ) => {
    let content;

    const [isInvalid, setIsInvalid] = useState(
      props.data.isValid === false ? true : false
    );

    const [customSelectData, setCustomSelectData] = useState<
      IFieldDataOptions[]
    >([]);

    const handleClassName = useCallback(() => {
      const defaultClassNames = [
        "mb-3",
        "col-md-" +
          (props.data.fieldColumnsWidth ? props.data.fieldColumnsWidth : 6),
        "align-self-end"
      ];

      if (props.data.visualRules?.extraClassNames) {
        defaultClassNames.push(props.data.visualRules?.extraClassNames);
      }

      // console.log(
      //   props.data.labelText,
      //   // props.data.visualRules?.extraClassNames
      //   defaultClassNames
      // );

      if (props.data.conditional) {
        defaultClassNames.push("invisible");
        if (props.data.groupingArea) {
          defaultClassNames.push("d-none");
        } else if (props.data.visualRules?.hardInvisibility) {
          defaultClassNames.push("d-none");
        }
      }
      // console.log(
      //   props.data.fieldId,
      //   props.checkFieldVisibility(props.data),
      //   defaultClassNames
      // );
      if (props.data.conditional && props.checkFieldVisibility(props.data)) {
        const elToRemove1 = defaultClassNames.indexOf("invisible");
        const elToRemove2 = defaultClassNames.indexOf("d-none");
        // console.log("#", props.data.labelText, elToRemove1, elToRemove2);
        if (elToRemove1 !== -1) {
          defaultClassNames.splice(elToRemove1);
        }
        if (elToRemove2 !== -1) {
          defaultClassNames.splice(elToRemove2);
        }
      }

      // const invisibilityClasses = ["invisible", "d-none"];
      // invisibilityClasses.map((_) => {
      //   if (defaultClassNames.includes(_)) {
      //     props.onChange(props.data, "");
      //   }
      // });

      // console.log(
      //   props.data.labelText,
      //   defaultClassNames,
      //   props.data.visualRules?.extraClassNames
      // );

      return defaultClassNames.join(" ") + " ";
    }, [props.data, ref]);

    useEffect(() => {
      handleClassName();
      props.setAttrVisibility(!props.checkFieldVisibility(props.data));

      if (
        [InputType.API_POSTCODE, InputType.API_HOUSENUMBER].includes(
          props.data.inputType
        )
      ) {
        // checkIfIsHardInvalidAttrSet();
      }

      checkIfIsHardInvalidAttrSet();

      // console.log(props.data.labelText, "val: " + props.data.value);
      if (props.data.inputType === InputType.SELECT_CUSTOM) {
        if (props.data.value) {
          // console.log(props.data.value);
          const apiValAsArray = props.data
            .value!.toString()
            .replaceAll(" ", "")
            .split(",");
          // console.log(apiValAsArray);
          const selectedOptions = props.data.options?.filter((_) => {
            if (apiValAsArray.includes(_.value.toString())) {
              return _;
            }
          });
          // console.log(selectedOptions);
          setCustomSelectData(selectedOptions ? selectedOptions : []);
        } else {
          setCustomSelectData([]);
        }
      }
      // console.log("a", props.data.fieldId, props.data.isValid, "a2", isInvalid);
    }, [props.data, ref]);

    const labelHint = () => {
      if (props.data.labelHint) {
        return (
          <OverlayTrigger
            overlay={
              <Tooltip id={props.data.fieldId}>{props.data.labelHint}</Tooltip>
            }
          >
            <a href="#" className="ms-3 text-600">
              <i className="bi bi-info-circle-fill"></i>
            </a>
          </OverlayTrigger>
        );
      }
      return;
    };

    const checkIfIsHardInvalidAttrSet = () => {
      // console.log(props.data.labelText, props.data.isValid);
      if (props.data.isValid === false) {
        // console.log("set " + props.data.fieldId + " to INvalid");
        setIsInvalid(true);
      } else if (props.data.isValid === true) {
        // console.log("set " + props.data.fieldId + " to VALID");
        setIsInvalid(false);
      } /* else {
        // do nothing
      } */
      // console.log(props.data.labelText, props.data.isValid);
    };

    const onBlurHandler = (ev: any) => {
      // const val = ev.target.value.toString().replace(/\s+/g, "");
      // console.log(ev.target.value);
      let val: string;
      if (props.data.value) {
        val = props.data.value.toString().replaceAll(/\s+/g, "");
      } else {
        val = ev.target.value.toString().replaceAll(/\s+/g, "");
      }

      // console.log(props.data.labelText, val);
      if (props.data.isMandatory || val !== "") {
        // if (props.data.isMandatory && props.data.isValid === undefined) {
        // props.data.isValid is undefined when has no validation on upper levels
        const isFieldValid = checkFieldIsValid(props.data, val);
        // console.log(props.data.fieldId, isFieldValid, props.data.isValid);
        // console.log(isFieldValid, props.data.labelText, val);
        // console.log(props.data.fieldId, props.data.isValid);
        // if (isFieldValid && props.data.confirmationFieldId === undefined) {
        if (props.data.isValid === false || isFieldValid === false) {
          // console.log("props.data.isValid === false");
          setIsInvalid(true);
        } else if (
          (isFieldValid && props.data.isValid === undefined) ||
          (isFieldValid && props.data.isValid === true)
        ) {
          // console.log(
          //   "isFieldValid true AND props.data.isValid === true",
          //   props.data.isValid
          // );
          setIsInvalid(false);
        } else {
          // console.log("Else");
          setIsInvalid(true);
        }
      } else if (props.data.isMandatory === undefined && !val) {
        // console.log("is Valid");
        setIsInvalid(false);
      }

      if (props.data.confirmationFieldId) {
        const referencedField = document.querySelector(
          ".field-id-" + props.data.confirmationFieldId.toLowerCase()
        ) as HTMLInputElement;
        // console.log(
        //   ".field-id-" + props.data.confirmationFieldId.toLowerCase(),
        //   referencedField,
        //   referencedField.value
        // );
        if (props.data.value !== referencedField.value) {
          setIsInvalid(true);
        } else {
          setIsInvalid(false);
        }
      }
    };

    // console.log(props.data.fieldId, props.data.isValid, isInvalid);
    // console.log(props.data.fieldId, props.data.value);

    const onToggleBtnClickHandler = () => {
      props.onChange(props.data, props.data.value != "1" ? "1" : "0");
    };

    const inputProps: Record<string, any> = {};

    //MEU CODIGO
    // inputProps.min = props.data.minValue !== undefined && props.data.minValue;
    // inputProps.max = props.data.maxValue !== undefined && props.data.maxValue;
    // inputProps.mask = props.data.validationRegex && props.data.validationRegex;
    // if(props.data.minLength) {
    //   inputProps.minLength = props.data.minLength;
    // }
    // if(props.data.maxLength) {
    //   inputProps.maxLength = props.data.maxLength;
    // }


    inputProps.minLength = props.data.minLength ? props.data.minLength : "false";
    inputProps.maxLength = props.data.maxLength ? props.data.maxLength : "false";

    if(props.data.minValue !== undefined) {
      inputProps.min = props.data.minValue;
    }
    if(props.data.maxValue !== undefined) {
      inputProps.max = props.data.maxValue;
    }
    if(props.data.validationRegex){ 
      inputProps.mask = props.data.validationRegex;
    }
    if(props.data.minLength) inputProps.minLength = props.data.minLength;
    if(props.data.maxLength) inputProps.maxLength = props.data.maxLength;


//OEM
    // inputProps.minLength = props.data.minLength ? props.data.minLength : "false";
    //inputProps.maxLength = props.data.maxLength ? props.data.maxLength : "false";

    // props.data.minValue !== undefined
    //   ? (inputProps.min = props.data.minValue)
    //   : "";
    // props.data.maxValue !== undefined
    //   ? (inputProps.max = props.data.maxValue)
    //   : "";
    // props.data.validationRegex
    //   ? (inputProps.mask = props.data.validationRegex)
    //   : "";
    // props.data.minLength ? (inputProps.minLength = props.data.minLength) : "";
    // props.data.maxLength ? (inputProps.maxLength = props.data.maxLength) : "";






    // if (props.data.inputType === InputType.NUMBER) {
    if (isIOS() && props.data.inputType === InputType.NUMBER) {
      inputProps.pattern = "[0-9]*";
      inputProps.inputmode = "numeric";
    }

    // console.log(props.data.fieldId, props.data);

    const onInputChangeHandler = (
      eventVal: any /*ChangeEvent<HTMLInputElement>*/
    ) => {
      const typedVal = eventVal;
      let newVal = typedVal;

      if (newVal && newVal !== "") {
        if (props.data.inputType !== InputType.DATE) {
          // if (props.data.validationNoSpecialChar === true) {
          //   console.log("#", allSpecialChars);
          //   for (let i = 0; i < allSpecialChars.length; i++) {
          //     newVal = newVal.replaceAll(allSpecialChars[i], "");
          //   }

          //   console.log("#", newVal);
          // }
          for (let i = 0; i < allSpecialChars.length; i++) {
            if (
              ![InputType.TEL, InputType.URL].includes(props.data.inputType)
            ) {
              // console.log("here", newVal);
              if (props.data.inputType !== InputType.EMAIL) {
                newVal = newVal.replaceAll(
                  new RegExp("\\" + allSpecialChars[i], "gi"),
                  ""
                );
              } else {
                newVal = newVal.replaceAll(
                  new RegExp("\\" + allSpecialCharsForEmail[i], "gi"),
                  ""
                );
              }
              // console.log("here", newVal);
            }

            if (
              props.data.validationRegex &&
              props.data.inputType === InputType.TEXT
            ) {
              // console.log("here", newVal);
              if (props.data.validationNoWhiteSpaces === true) {
                // console.log("remove whitespaces");
                newVal = newVal.replaceAll(" ", "");
              } else {
                // console.log("here", newVal);
                newVal = newVal.replaceAll(
                  new RegExp(props.data.validationRegex, "gi"),
                  ""
                );
              }
              // console.log("here", newVal);
            }
          }
        }

        if (props.data.inputType == InputType.EMAIL) {
          newVal = newVal.replaceAll(" ", "");
        }
        if (![InputType.EMAIL, InputType.URL].includes(props.data.inputType)) {
          newVal = newVal.replaceAll("@", "");
          newVal = newVal.replaceAll(".", "");
          newVal = newVal.replaceAll("", "");
          // console.log("here", newVal);
        }

        if (props.data.inputType === InputType.NUMBER) {
          if (!props.data.isDecimal) {
            newVal = newVal.replaceAll(".", "");
          }
          // console.log(props.data.minValue);
          if (props.data.minValue && props.data.minValue >= 0) {
            // console.log("#");
            newVal = newVal.replaceAll("-", "");
          }
        }

        // console.log("here", newVal);
        if (props.data.maxLength && newVal.length > props.data.maxLength) {
          // console.log("cut", newVal, props.data.maxLength);
          newVal = newVal.slice(0, props.data.maxLength);
        }
        // @INPROGRESS
        if (props.data.validationRegex) {
          // console.log("newVal", newVal);
          const regex = new RegExp(props.data.validationRegex, "gm");
          // console.log(
          //   "has regex",
          //   regex,
          //   newVal,
          //   regex.test(newVal),
          //   regex.test(newVal),
          //   regex.test(newVal) === false
          // );

          if (
            regex.test(newVal) === false &&
            ![InputType.IBAN, InputType.TEL].includes(props.data.inputType)
          ) {
            // console.log(
            //   "failed regex than replace",
            //   newVal.replaceAll(regex, "")
            // newVal.replaceAll(new RegExp(/[^0-9]+/, "g"), "")
            // );
            // console.log(props.data.labelText);
            newVal = newVal.replaceAll(regex, "");
            // console.log("#", newVal);
          }
        }
        if (props.data.validationNoNumbers === true) {
          newVal = newVal.replaceAll(/[0-9]+/g, "");
          // console.log(newVal);
        }
        if (props.data.validationOnlyNumbers === true) {
          newVal = newVal.replace(/[^0-9]+/g, "");
          // console.log("#");
        }
        if (props.data.validationNoWhiteSpaces === true) {
          // console.log("remove whitespaces");
          newVal = newVal.replaceAll(" ", "");
        }
      }
      // console.log("newVal Final", newVal);
      props.onChange(props.data, newVal);
    };
    // console.log(props.data.value);

    const selectOnChangeHandler = (
      // ev:  any
      ev: ChangeEvent<HTMLSelectElement>
    ) => {
      const newVal = ev.target.value;
      // let newVal = ev.target.value;
      // if (newVal === "Select") {
      //   newVal = undefined;
      // }
      // console.log(props.data.fieldId, newVal);
      // alert(props.data.fieldId + " new val is: " + newVal);
      props.onChange(props.data, newVal);
    };

    const customSelectOnChangeHandler = (val: any) => {
      // console.log(ev);
      let newVal = val;
      newVal = newVal.map((_: { label: string; value: string }) => {
        return _.value.toString();
      });
      // console.log(newVal, newVal.join(", "));
      // setCustomSelectData(val);

      if (newVal.length === 0) {
        // console.log("val = empty");
        props.onChange(props.data, "");
        setCustomSelectData([]);
      } else {
        // console.log("val is something", newVal);
        props.onChange(props.data, newVal.join(", "));
      }
    };

    const onTyping = (ev: any) => {
      if (props.data.inputType === InputType.NUMBER) {
        if (["-", "e"].includes(ev.key)) {
          ev.preventDefault();
        }
      }
    };

    const onPaste = (ev: any) => {
      // console.log("#", ev, ev.keyCode);
      // if (props.data.inputType === InputType.NUMBER) {
      //   if (["-"].includes(ev.key)) {
      //     ev.preventDefault();
      //   }
      // }
    };

    // function validateField() {
    //   if(props.data.inputType === InputType.NUMBER) {
    //     if (!props.data.isDecimal) {
    //       newVal = newVal.replaceAll(".", "");
    //     }
    //     if (props.data.minValue && props.data.minValue >= 0) {
    //       console.log("#");
    //       newVal = newVal.replaceAll("-", "");
    //     }
    //   }
    // }

    // console.log(
    //   props.data.fieldId,
    //   props.data.isValid,
    //   isInvalid,
    //   props.data.value
    // );

    switch (props.data.inputType) {
      case InputType.RADIO:
        // console.log(props.data.labelText, props.data.value);
        content = (
          <FormGroup
            className={handleClassName() + "d-flex align-items-center "}
          >
            {props.data.labelText !== "" && (
              <Form.Label>{props.data.labelText}</Form.Label>
            )}
            {labelHint()}
            <ToggleButtonGroup
              type="radio"
              name={
                "tbg-radio-" +
                pascalSentence(props.data.labelText).toLocaleLowerCase()
              }
              // className={classNames}
              ref={ref}
              data-field-id={props.data.fieldId}
              onChange={(val: string) => props.onChange(props.data, val)}
              defaultValue={props.data.value}
            >
              {props.data.options?.map((radioOption, index) => {
                return (
                  <ToggleButton
                    key={index}
                    id={
                      "tbg-radio-" +
                      pascalSentence(props.data.labelText).toLocaleLowerCase() +
                      index
                    }
                    value={radioOption.value}
                  >
                    {radioOption.label}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          </FormGroup>
        );
        break;

      case InputType.TEL:
        content = (
          <Form.Group
            // className={classNames}
            className={handleClassName()}
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            data-field-id={props.data.fieldId}
          >
            <Form.Label>{props.data.labelText}</Form.Label>
            {labelHint()}
            {/* <PhoneInputWithCountrySelect
              ref={ref}
              international
              countries={["NL"]}
              // countrySelectProps={{ unicodeFlags: true }}
              // value={formatPhoneNumberIntl(props.data.value!.toString())}
              value={props.data.value?.toString()}
              // onChange={
              //   (val) =>
              //     props.onChange(props.data.fieldId, val ? val!.toString() : "")
              //   // console.log(val)
              // }
              // onChange={(val) =>
              //   onInputChangeHandler({
              //     target: { value: val ? val.toString() : "" }
              //   })
              // }
              onChange={onInputChangeHandler}
              onBlur={onBlurHandler}
              // onFocus={onFocusHandler}
              className={isInvalid ? " is-invalid" : ""}
              countryCallingCodeEditable={false}
              // initialValueFormat="national"
              defaultCountry={"NL"}
              smartCaret={true}
              rules={{
                required: true
              }}
              {...inputProps}
              // focusInputOnCountrySelection
            /> */}

            <div className={"PhoneInput" + (isInvalid ? " is-invalid" : "")}>
              <div className="PhoneInputCountry">
                <div className="PhoneInputCountryIcon PhoneInputCountryIcon--border">
                  <img
                    src={NLflag}
                    alt="@TODO Step here"
                    className="PhoneInputCountryIconImg"
                  />
                </div>
              </div>
              <Input
                ref={ref}
                country="NL"
                international
                withCountryCallingCode
                className={"PhoneInputInput"}
                value={props.data.value?.toString()}
                onChange={onInputChangeHandler} //estou aqui o erro
                onBlur={onBlurHandler}
                rules={{
                  required: true
                }}
                {...inputProps}
              />
            </div>

            {isInvalid && (
              <div className="invalid-feedback">
                {props.data.validationMessage!}
              </div>
            )}
          </Form.Group>
        );
        break;

      case InputType.SELECT:
        // console.log(props.data.labelText, props.data.value);
        content = (
          <Form.Group
            // className={classNames}
            className={handleClassName()}
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            data-field-id={props.data.fieldId}
          >
            <Form.Label>{props.data.labelText}</Form.Label>
            {labelHint()}
            <Form.Select
              ref={ref}
              aria-label={props.data.labelText}
              className={
                "text-capitalize" +
                (isInvalid || props.data.isValid === false ? " is-invalid" : "")
              }
              // onChange={(ev) =>
              //   props.onChange(props.data.fieldId, ev.target.value)
              // }
              onChange={selectOnChangeHandler}
              onBlur={onBlurHandler}
              defaultValue={props.data.value}
              // defaultValue={undefined}
              // value={props.data.value}
              // defaultValue={"select"}
            >
              <option>Select</option>
              {props.data.options?.map((o, index) => {
                return (
                  <option value={o.value} key={index}>
                    {o.label}
                  </option>
                );
              })}
            </Form.Select>
            {(isInvalid ||
              (props.data.isValid !== undefined &&
                props.data.isValid === false)) && (
              <div className="invalid-feedback">
                {props.data.validationMessage!}
              </div>
            )}
          </Form.Group>
        );
        break;

      case InputType.SELECT_CUSTOM:
        content = (
          <Form.Group
            // className={classNames}
            className={handleClassName()}
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            data-field-id={props.data.fieldId}
            // ref={ref}
          >
            <Form.Label>{props.data.labelText}</Form.Label>
            {labelHint()}
            {/* <Form.Select
              ref={ref}
              aria-label={props.data.labelText}
              className={"text-capitalize" + (isInvalid ? " is-invalid" : " ")}
              onChange={onSelectChangeHandler}
              onBlur={onBlurHandler}
            >
              <option>Select</option>
              {props.data.options?.map((o, index) => {
                return (
                  <option value={o.value} key={index}>
                    {o.text}
                  </option>
                );
              })}
            </Form.Select> */}
            <Select
              className={
                "form-control custom-select p-0 " +
                (isInvalid ? "is-invalid" : "")
              }
              // className={"form-control " + (isInvalid ? "is-invalid" : "")}
              // className={
              //   "form-control " + isInvalid
              //     ? " is-invalid"
              //     : "" + "form-control  "
              // }
              isMulti={props.data.isMultiSelect}
              ref={ref}
              value={customSelectData}
              // value={handleCustomSelectValues()}
              options={props.data.options}
              onChange={customSelectOnChangeHandler}
              onBlur={onBlurHandler}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  border: "none",
                  padding: "0"
                })
              }}
            />
            {(isInvalid ||
              (props.data.isValid !== undefined &&
                props.data.isValid === false)) && (
              <div className="invalid-feedback">
                {props.data.validationMessage!}
              </div>
            )}
          </Form.Group>
        );
        break;

      case InputType.DATE:
      case InputType.MONTH:
        content = (
          <Form.Group
            className={handleClassName()}
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            // data-field-id={props.data.fieldId}
          >
            <div ref={ref}></div>
            <Form.Label>{props.data.labelText}</Form.Label>
            {labelHint()}
            {/* <Form.Control
              className={
                "form-control-type-" +
                props.data.inputType +
                " " +
                "field-id-" +
                props.data.fieldId.toLowerCase() +
                (isInvalid ? " is-invalid" : "")
              }
              onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                props.onChange(props.data.fieldId, ev.target.value)
              }
              onBlur={onBlurHandler}
              ref={ref}
              disabled={props.data.isDisabled}
              type={props.data.inputType}
              min={
                props.data.minValue
                  ? moment(props.data.minValue).format(
                      props.data.inputType === InputType.MONTH
                        ? americanDateFormatYearAndMonth
                        : americanDateFormat
                    )
                  : ""
              }
              max={
                props.data.maxValue
                  ? moment(props.data.maxValue).format(
                      props.data.inputType === InputType.MONTH
                        ? americanDateFormatYearAndMonth
                        : americanDateFormat
                    )
                  : ""
              }
              placeholder={props.data.inputPlaceholder}
            /> */}

            <InputMask
              className={
                "form-control form-control-type-" +
                props.data.inputType +
                " " +
                "field-id-" +
                props.data.fieldId.toLowerCase() +
                (isInvalid ? " is-invalid" : "")
              }
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                // console.log("ev", ev.target.value);
                onInputChangeHandler(ev.target.value);
              }}
              // onChange={(ev: ChangeEvent<HTMLInputElement>) => {
              //   onInputDateChangeHandler(ev.target.value);
              //   // const val = ev.target.value;
              //   // val && onInputChangeHandler(val);
              // }}
              mask="99/99/9999"
              placeholder="dd/mm/yyyy"
              onBlur={onBlurHandler}
              ref={ref}
              value={props.data.value}
              // children={<Form.Control />}
            />
            {/* {
                ((inputProps: any) => {
                  return <Form.Control {...inputProps} />;
                }) as any
              } */}
            {/* </InputMask> */}
            {(isInvalid || !props.data.isValid) && (
              <div className="invalid-feedback">
                {props.data.validationMessage!}
              </div>
            )}
          </Form.Group>
        );
        break;

      case InputType.TOGGLE_ADD_FIELD_BUTTON:
        content = (
          <Form.Group
            // className={handleClassName() + "d-flex justify-content-center"}
            className={"d-flex justify-content-center mb-4"}
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            ref={ref}
          >
            {props.data.value == "0" && (
              <Button
                variant="outline-primary"
                className={"col-8"}
                onClick={onToggleBtnClickHandler}
              >
                <i className="bi bi-plus-circle me-2 fs-6" />
                {props.data.labelText}
              </Button>
            )}
            {props.data.value == "1" && (
              <a href="javascript:;" onClick={onToggleBtnClickHandler}>
                {props.data.toggleText}
              </a>
            )}
          </Form.Group>
        );
        break;

      case InputType.CUSTOM_TITLE:
        content = (
          <Form.Group className={"mt-4"} ref={ref}>
            <p className={handleClassName()}>{props.data.labelText}</p>
          </Form.Group>
        );
        break;

      case InputType.CUSTOM_HINT:
        content = (
          <Form.Group className={""} ref={ref}>
            <p className={handleClassName()}>{props.data.labelText}</p>
          </Form.Group>
        );
        break;

      case InputType.EMPTY_BLOCK:
        content = (
          <Form.Group
            className={handleClassName()}
            // controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            ref={ref}
          >
            {/* &nbsp; */}
          </Form.Group>
        );
        break;

      case InputType.HIDDEN:
        content = (
          <Form.Control
            className={
              "d-none invisible form-control form-control-type-" +
              props.data.inputType +
              " " +
              "field-id-" +
              props.data.fieldId.toLowerCase() +
              (isInvalid ? " is-invalid" : "")
            }
            onChange={(ev) => onInputChangeHandler(ev.target.value)}
            ref={ref}
            type="hidden"
            value={props.data.value}
            {...inputProps}
          />
        );
        break;

      case InputType.CUSTOM_MASK:
        content = (
          <Form.Group
            className={handleClassName()}
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            // data-field-id={props.data.fieldId}
          >
            <Form.Label>{props.data.labelText}</Form.Label>
            {labelHint()}
            <InputMask
              className={
                "form-control form-control-type-" +
                props.data.inputType +
                " " +
                "field-id-" +
                props.data.fieldId.toLowerCase() +
                (isInvalid ? " is-invalid" : "")
              }
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                onInputChangeHandler(ev.target.value);
              }}
              // mask={[/^NL[0-9]{9}[B][0-9]{2}$/gi]}
              mask={[props.data.visualRules!.mask!]}
              {...inputProps}
              placeholder={props.data.inputPlaceholder}
              onBlur={onBlurHandler}
              ref={ref}
              value={props.data.value}
            />
            {isInvalid && (
              <div className="invalid-feedback">
                {props.data.validationMessage!}
              </div>
            )}
          </Form.Group>
        );
        break;

      default:
        content = (
          <Form.Group
            className={
              handleClassName()
              // classNames
              //  + (props.checkFieldVisibility(props.data) ? "invisible " : "")
            }
            controlId={"formControl" + pascalSentence(props.data.labelText)}
            aria-disabled={props.data.isDisabled}
            // data-field-id={props.data.fieldId}
          >
            <Form.Label>{props.data.labelText}</Form.Label>
            {labelHint()}
            <Form.Control
              className={
                "form-control-type-" +
                props.data.inputType +
                " " +
                "field-id-" +
                props.data.fieldId.toLowerCase() +
                (isInvalid || props.data.isValid === false ? " is-invalid" : "")
              }
              // onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              //   props.onChange(props.data.fieldId, ev.target.value)
              // }
              onChange={(ev) => onInputChangeHandler(ev.target.value)}
              onBlur={onBlurHandler}
              ref={ref}
              disabled={props.data.isDisabled}
              type={props.data.inputType}
              placeholder={props.data.inputPlaceholder}
              {...inputProps}
              onKeyDown={onTyping}
              onPaste={onPaste}
              // value={props.data.value}
            />
            {/* <div ref={ref}></div> */}
            {/* <InputMask
              className={
                "form-control form-control-type-" +
                props.data.inputType +
                " " +
                "field-id-" +
                props.data.fieldId.toLowerCase() +
                (isInvalid ? " is-invalid" : "")
              }
              // onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              //   props.onChange(props.data.fieldId, ev.target.value)
              // }
              // onChange={onInputChangeHandler}
              // onBlur={onBlurHandler}
              // ref={ref}
              // disabled={props.data.isDisabled}
              // type={props.data.inputType}
              // placeholder={props.data.inputPlaceholder}
              // {...inputProps}
              // mask={"_____"}
              // replacement={{ _: /\d/ }}
            /> */}
            {(isInvalid ||
              (props.data.isValid !== undefined &&
                props.data.isValid === false)) && (
              <div className="invalid-feedback">
                {props.data.validationMessage!}
              </div>
            )}
          </Form.Group>
        );
        break;
    }

    return <> {content} </>;
  }
);

FormControl.displayName = "FormControl";
