import axios from "axios";
import {
  IFieldCountriesDataOptions,
  IFieldDataOptions
} from "../Types/FieldDataConfig";

const api_url_njs = process.env.REACT_APP_ROOT_DOMAIN;
const api_auth_username_njs = process.env.REACT_APP_API_USER_NJS!;
const api_auth_password_njs = process.env.REACT_APP_API_PASSWORD_NJS!;

const root_url = api_url_njs;
const api_auth_username = api_auth_username_njs;
const api_auth_password = api_auth_password_njs;

const nodeServerEndpoint = "/api/leasing-form";

const defaultUrlEnpoint = nodeServerEndpoint;

export const getAllRetailerData = async (applicationGuid: string) => {
  return axios
    .get(
      root_url +
        defaultUrlEnpoint +
        "/GetRetailerThemeByGuid?guid=" +
        applicationGuid,
      {
        auth: {
          username: api_auth_username,
          password: api_auth_password
        }
      }
    )
    .then((res) => {
      // console.log(res);
      // console.log(res.data);
      const JSONobject = res.data;

      return JSONobject;
    });
};

export const getCountryListApiCall = async () => {
  const countryList: IFieldCountriesDataOptions[] = [];
  return axios
    .get(root_url + "/api/getCountries", {
      auth: {
        username: api_auth_username,
        password: api_auth_password
      }
    })
    .then((res) => {
      return res.data.map((_: any) => {
        return {
          label: _.CONTRYDESC,
          value: +_.ID,
          IsEU: _.IsEU
        };
      });
    })
    .catch((err) => {
      countryList.push({
        label: "Nederlandse",
        value: 1,
        IsEU: true
      });
    });
};

export const getMonthList = async () => {
  const countryList: IFieldDataOptions[] = [];
  return axios
    .get(root_url + "/getMonths", {
      auth: {
        username: api_auth_username,
        password: api_auth_username
      }
    })
    .then((res) => {
      return res.data.map((_: any) => {
        return {
          text: _.Label_NL,
          value: _.Id
        };
      });
    })
    .catch((err) => {
      countryList.push({
        label: "Januari",
        value: 1
      });
    });
};

export const saveStepData = async (urlToSave: string, dataToSave: any) => {
  axios
    .post(urlToSave, dataToSave, {
      auth: {
        username: api_auth_username,
        password: api_auth_password
      }
    })
    .then((res) => {
      // console.log(res);
    });
};

export const getAddressApiCall = (
  zipCode: string | number,
  houseNo: string | number
) => {
  return axios
    .get(
      root_url + `/api/getAddress?zipCode=${zipCode}&houseNumber=${houseNo}`,
      {
        auth: {
          username: api_auth_username,
          password: api_auth_password
        }
      }
    )
    .then((res) => {
      return {
        city: res.data.city.trim(),
        street: res.data.street.trim()
      };
    })
    .catch((err) => {
      const returnMsg = {
        city: "De combinatie van postcode en huisnummer is incorrect",
        street: "De combinatie van postcode en huisnummer is incorrect"
      };
      return Promise.reject(returnMsg);
    });
};

export const getOthersNetIncomeListApiCall = () => {
  return axios
    .get(root_url + "/getOtherIncomeTypes/", {
      auth: {
        username: api_auth_username,
        password: api_auth_password
      }
    })
    .then((res) => {
      return res.data.map((_: any) => {
        return {
          label: _.Label,
          value: _.Id
        };
      });
    });
};

export const validateIBANApiCall = async (ibanToValidate: string) => {
  return await axios
    .get(root_url + "/api/getIBAN/" + ibanToValidate, {
      auth: {
        username: api_auth_username,
        password: api_auth_password
      }
    })
    .then((res) => {
      return res.data.Result.IsValid;
    })
    .catch((error) => {
      console.log("error trying to validate IBAN");
    });
};
