import { useLocation } from "react-router-dom";
import ThankYouImage from "../Assets/Images/thank-you-icon.png";
import StepIcon from "../Assets/Images/step-icon.svg";

const ThanksPage = () => {
  const location = useLocation();

  // console.log(location);

  return (
    <div className="container wizard-container rounded p-5 my-5 text-center shadow-lg ">
      <div className="stepper mb-4">
        <div className="steps-line d-flex justify-content-around ">
          <div className="step-item w-100 text-center done ">
            <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
              Persoonlijke gegevens
            </span>
            <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
              <span className="step-number">
                <i className="bi bi-check-circle"></i>
              </span>
            </div>
          </div>
          <div className="step-item w-100 text-center done ">
            <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
              Beroepsgegevens/Werk
            </span>
            <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
              <span className="step-number">
                <i className="bi bi-check-circle"></i>
              </span>
            </div>
          </div>
          <div className="step-item w-100 text-center done ">
            <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
              Financiële gegevens
            </span>
            <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
              <span className="step-number">
                <i className="bi bi-check-circle"></i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <img src={ThankYouImage} />
      <h1>Beste meneer/mevrouw {location.state.name},</h1>
      <p>Wij hebben uw private lease aanvraag ontvangen.</p>
      <div>Het is niet mogelijk om uw aanvraag direct te beoordelen.</div>
      <p> Wij laten u de uitslag zo snel mogelijk weten via e-mail.</p>
    </div>
  );
};

export default ThanksPage;
