import {
  ConditionalOperator,
  IFieldDataCondition
} from "./Types/FieldDataConfig";
import { TFormField } from "./Components/WizardCmp";
import moment from "moment";
import { getB2BSubSteps, getB2CSubSteps } from "./Store/WizardController";
import { InputType } from "./Types/InputType";
import { monthList } from "./Store/MonthList";

export const defaultDateFormat = "DD-MM-YYYY";
export const americanDateFormat = "YYYY-MM-DD";
export const americanDateFormatYearAndMonth = "YYYY-MM";

export const pascalSentence = function camelSentence(str: string) {
  return (" " + str)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
};

export const colorAdjust = (color: string, amount: number) => {
  return (
    "#" +
    color
      .replace(/^#/, "")
      .replace(/../g, (color) =>
        (
          "0" +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2)
      )
  );
};

export function findElAndIndexInDeepArray(
  list: any[],
  attrName: string,
  value: string
  // ): { element: any; index: number | Map<any, any> } {
): { element: TFormField; index: number } {
  // console.log("findIndexInDeepArray", list, attrName, value);

  let itemIndex: any;
  // let fieldFoundList: Array<{ element: TFormField; index: number }>;
  // const fieldFoundList: any = [];

  list.map((_, mapIndex) => {
    // console.log("_", _);
    // if (_[attrName] === value && _.isFieldVisible !== false) {
    if (_[attrName] === value) {
      // console.log("found", list[mapIndex]);
      // if (_.isFieldVisible !== false) {
      itemIndex = {
        element: list[mapIndex],
        index: mapIndex
      };
      // }
      // fieldFoundList.push({
      //   element: list[mapIndex],
      //   index: mapIndex
      // });
    }
  });

  // if (fieldFoundList.length > 1) {
  //   // console.log("found list", fieldFoundList);
  //   // console.log(
  //   //   fieldFoundList.filter((_: any) => _.element.isFieldVisible !== false)[0]
  //   // );
  //   const visibleField = fieldFoundList.filter(
  //     (_: any) => _.element.isFieldVisible !== false
  //   )[0];

  //   if (visibleField !== undefined) {
  //     // console.log("#list and is some visible");
  //     itemIndex = visibleField;
  //   } else {
  //     // console.log("#list and all invisible");
  //     itemIndex = fieldFoundList[0];
  //   }
  // }

  // console.log(itemIndex);

  // console.log("index is:", itemIndex);
  return itemIndex;
  // return fieldFoundList[0];
}

/**
 * For string fields use only Equal
 * @param referencedField
 * @param condition
 * @returns
 */
export function checkDependencyMatch(
  referencedField: TFormField,
  condition: IFieldDataCondition
): boolean {
  // console.log(referencedField);
  // console.log(condition);
  if (referencedField) {
    // console.log(
    //   referencedFieldValue,
    //   conditionValue,
    //   conditionValue instanceof Array
    // );

    let result = false;

    if (referencedField.value) {
      const conditionValue = condition.value;
      const referencedFieldValue = referencedField.value!;

      switch (condition.operator) {
        case ConditionalOperator.LessOrEqualThan:
          result = referencedFieldValue <= conditionValue;
          break;
        case ConditionalOperator.LessThan:
          result = referencedFieldValue < conditionValue;
          break;
        case ConditionalOperator.GreatherOrEqualThan:
          result = referencedFieldValue >= conditionValue;
          break;
        case ConditionalOperator.GreatherThan:
          result = referencedFieldValue > conditionValue;
          break;
        case ConditionalOperator.Different:
          result = referencedFieldValue != conditionValue;
          break;
        case ConditionalOperator.InArray:
          result = (conditionValue as Array<string>).includes(
            referencedFieldValue.toString()
          );
          break;
        case ConditionalOperator.NotInArray:
          //Must be array of string, otherwise if referencedFieldValue is 1 it will always pass
          result = !(conditionValue as Array<string>).includes(
            referencedFieldValue.toString()
          );
          // console.log(result, referencedField, condition);
          break;
        default:
          // Check if condition is an array;
          if (conditionValue instanceof Array) {
            // console.log(
            //   "conditional value is in array",
            //   referencedFieldValue,
            //   conditionValue,
            //   conditionValue.includes(+referencedFieldValue)
            // );
            result = conditionValue.includes(+referencedFieldValue);
          }
          // If not array just compare value equalness
          else {
            // console.log(
            //   "conditional value is NOT in array",
            //   referencedFieldValue == conditionValue
            // );
            result = referencedFieldValue == conditionValue; // DOnt check type here
          }
          break;
      }
      return result;
    }
  }
  return false;
}

export function checkDateDependencyMatch(
  referencedField: TFormField,
  condition: IFieldDataCondition
): boolean {
  // console.log(referencedField.value, condition.value);
  const referencedFieldValue = moment(referencedField.value!).unix();
  // console.log(referencedFieldValue);
  // const conditionValue = moment(moment(condition.value).format("YYYY-MM-DD"));
  const conditionValue = moment(condition.value, defaultDateFormat).unix();
  // console.log(referencedFieldValue, condition.operator, conditionValue);
  // console.log(moment(referencedFieldValue), moment(conditionValue));
  // console.log(referencedFieldValue >= conditionValue);
  // console.log(
  //   moment(referencedFieldValue).diff(moment(conditionValue), "year")
  // );
  let r = false;
  switch (condition.operator) {
    case ConditionalOperator.LessOrEqualThan:
      r = referencedFieldValue <= conditionValue;
      break;
    case ConditionalOperator.LessThan:
      r = referencedFieldValue < conditionValue;
      break;
    case ConditionalOperator.GreatherOrEqualThan:
      r = referencedFieldValue >= conditionValue;
      break;
    case ConditionalOperator.GreatherThan:
      r = referencedFieldValue > conditionValue;
      break;
    default:
      r = referencedFieldValue === conditionValue;
  }
  return r;
}

export function checkDateDependency2FieldsMatch(
  referencedFieldMonth: TFormField,
  referencedFieldYear: TFormField,
  condition: IFieldDataCondition
): boolean {
  // console.log(
  //   "#-#",
  //   referencedFieldMonth,
  //   referencedFieldYear
  //   // condition.value
  // );

  if (referencedFieldMonth.value && referencedFieldYear.value) {
    // console.log(
    //   "start checking values",
    //   referencedFieldMonth.value,
    //   referencedFieldYear.value
    // );
    const monthCode = monthList
      .filter((el) => el.Id === +referencedFieldMonth.value!)[0]
      .Code.toString();

    const referencedFieldValue = moment(
      referencedFieldYear.value! + "-" + monthCode.padStart(2, "0") + "-01",
      "YYYY-MM-DD"
    ).unix();
    // console.log(
    //   moment(
    //     referencedFieldYear.value! + "-" + monthCode.padStart(2, "0") + "-01",
    //     "YYYY-MM-DD"
    //   )
    // );
    // const conditionValue = moment(moment(condition.value).format("YYYY-MM-DD"));
    const conditionValue = moment(condition.value, defaultDateFormat).unix();
    // console.log(referencedFieldValue, condition.operator, conditionValue);
    // console.log(moment(referencedFieldValue), moment(conditionValue));
    // console.log(referencedFieldValue >= conditionValue);
    // console.log(
    //   moment(referencedFieldValue).diff(moment(conditionValue), "year")
    // );
    // console.log("#1", referencedFieldValue, condition.operator, conditionValue);
    // console.log("#", referencedFieldValue, condition.operator, conditionValue);

    let r = false;
    switch (condition.operator) {
      case ConditionalOperator.LessOrEqualThan:
        r = referencedFieldValue <= conditionValue;
        break;
      case ConditionalOperator.LessThan:
        r = referencedFieldValue < conditionValue;
        break;
      case ConditionalOperator.GreatherOrEqualThan:
        r = referencedFieldValue >= conditionValue;
        break;
      case ConditionalOperator.GreatherThan:
        r = referencedFieldValue > conditionValue;
        break;
      default:
        r = referencedFieldValue === conditionValue;
    }
    // console.log("return: " + r);
    return r;
  }
  // console.log("has no values to check then return false");
  return false;
}

// export function getSubStepObjByAttr(
//   attrName: string,
//   val: string,
//   // formType: "b2b" | "b2c"
//   formType: string
// ) {
//   const subStepList: any =
//     formType === "b2c" ? getB2CSubSteps() : getB2BSubSteps();

//   // console.log(attrName, val, formType, subStepList);

//   return subStepList.filter((_: any) => {
//     // console.log("_", _[attrName]);
//     if (_[attrName] === val) {
//       // console.log(_);
//       return _;
//     }
//   })[0];
// }

/** return true if field is valid */
export function checkFieldValidation(field: TFormField) {
  // console.log(
  //   "fieldId:" +
  //     field.fieldId +
  //     " - " +
  //     field.labelText +
  //     ": " +
  //     field.value +
  //     " ----- " +
  //     field.inputType
  // );
  // console.log(field.minLength, field.maxLength);
  // console.log(field.minValue, field.maxValue);

  // Check if empty or undefined
  if (field.value === "" || field.value === undefined) {
    // console.log("#", field.value);
    return false;
  }

  // Check if is inside the char length if is set
  if (field.minLength && (field.value + "").length < field.minLength) {
    // console.log("#");
    return false;
  } else if (field.maxLength && (field.value + "").length > field.maxLength) {
    // console.log("#");
    return false;
  }

  // If is number do check if in the range "0' to "maxValue'
  if (
    field.inputType === InputType.NUMBER &&
    field.minValue &&
    +field.value < field.minValue
  ) {
    // console.log("failed min val");
    return false;
  } else if (
    field.inputType === InputType.NUMBER &&
    field.maxValue &&
    +field.value > field.maxValue
  ) {
    // console.log("failed max val");
    return false;
  }

  // Regex for test email
  if (
    field.inputType === InputType.EMAIL &&
    field.value &&
    !/.+@.+\.[A-Za-z]+$/.test(field.value.toString())
  ) {
    // console.log("failed mail val");
    return false;
  }

  // Regex for test URL
  if (
    field.inputType === InputType.URL &&
    field.value &&
    !/^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9-_.]+\.[a-zA-Z]+(:\d+)?(\/[a-zA-Z\d.\-_]*)*[a-zA-Z.!@#$%&=-_'":,.?\d*)(]*$/.test(
      field.value.toString()
    )
  ) {
    console.log("failed URL val");
    return false;
  }

  // Regex for test ZIP_CODE
  if (
    field.inputType === InputType.API_POSTCODE &&
    field.value &&
    !/([a-zA-Z]{2})?[\s-]*[0-9]{4,5}[\s-]*([a-zA-Z]{2})?/gm.test(
      field.value.toString()
    )
  ) {
    return false;
  }

  if (
    field.inputType === InputType.SELECT &&
    field.value &&
    !/^[1-9]d*$/.test(field.value.toString())
  ) {
    return false;
  }

  if (
    field.inputType === InputType.TEL &&
    field.value &&
    // /^\+[31]{2}\s[0-9]{1}\s[0-9]{7}[^a-z]$/i.test(field.value + "")
    // /^\(?([+]31|0031|0)-?6(\s?|-)([0-9]\s{0,3}){8}$/i.test(field.value + "")
    /^\+[31]{2}[0-9]{8}[^a-z]$/i.test(field.value.toString())
  ) {
    // console.log("failed PHONE check");
    return false;
  }

  return true;
}

function isMailFieldValid(field: TFormField) {
  if (
    field.inputType === InputType.EMAIL &&
    field.value &&
    // !/.+@.+\.[A-Za-z]+$/.test(field.value.toString())
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      field.value.toString()
    )
  ) {
    return false;
  }
  return true;
}

function isUrlFieldValid(field: TFormField) {
  if (
    field.inputType === InputType.URL &&
    field.value &&
    !/^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9-_.]+\.[a-zA-Z]+(:\d+)?(\/[a-zA-Z\d.\-_]*)*[a-zA-Z.!@#$%&=-_'":,.?\d*)(]*$/.test(
      // !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(
      // !/^(?:www\.)?([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b)*(\/[/\d\w.-]*)*(?:[?])*(.+)*/.test(
      field.value.toString()
    )
  ) {
    return false;
  }
  return true;
}

function isIBANFieldValid(field: TFormField) {
  if (
    field.inputType === InputType.IBAN &&
    field.value &&
    !/^AL\d{10}[0-9A-Z]{16}$|^AD\d{10}[0-9A-Z]{12}$|^AT\d{18}$|^BH\d{2}[A-Z]{4}[0-9A-Z]{14}$|^BE\d{14}$|^BA\d{18}$|^BG\d{2}[A-Z]{4}\d{6}[0-9A-Z]{8}$|^HR\d{19}$|^CY\d{10}[0-9A-Z]{16}$|^CZ\d{22}$|^DK\d{16}$|^FO\d{16}$|^GL\d{16}$|^DO\d{2}[0-9A-Z]{4}\d{20}$|^EE\d{18}$|^FI\d{16}$|^FR\d{12}[0-9A-Z]{11}\d{2}$|^GE\d{2}[A-Z]{2}\d{16}$|^DE\d{20}$|^GI\d{2}[A-Z]{4}[0-9A-Z]{15}$|^GR\d{9}[0-9A-Z]{16}$|^HU\d{26}$|^IS\d{24}$|^IE\d{2}[A-Z]{4}\d{14}$|^IL\d{21}$|^IT\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^[A-Z]{2}\d{5}[0-9A-Z]{13}$|^KW\d{2}[A-Z]{4}22!$|^LV\d{2}[A-Z]{4}[0-9A-Z]{13}$|^LB\d{6}[0-9A-Z]{20}$|^LI\d{7}[0-9A-Z]{12}$|^LT\d{18}$|^LU\d{5}[0-9A-Z]{13}$|^MK\d{5}[0-9A-Z]{10}\d{2}$|^MT\d{2}[A-Z]{4}\d{5}[0-9A-Z]{18}$|^MR13\d{23}$|^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$|^MC\d{12}[0-9A-Z]{11}\d{2}$|^ME\d{20}$|^NL\d{2}[A-Z]{4}\d{10}$|^NO\d{13}$|^PL\d{10}[0-9A-Z]{,16}n$|^PT\d{23}$|^RO\d{2}[A-Z]{4}[0-9A-Z]{16}$|^SM\d{2}[A-Z]\d{10}[0-9A-Z]{12}$|^SA\d{4}[0-9A-Z]{18}$|^RS\d{20}$|^SK\d{22}$|^SI\d{17}$|^ES\d{22}$|^SE\d{22}$|^CH\d{7}[0-9A-Z]{12}$|^TN59\d{20}$|^TR\d{7}[0-9A-Z]{17}$|^AE\d{21}$|^GB\d{2}[A-Z]{4}\d{14}$/.test(
      field.value.toString()
    )
    // !/^NL\d{2}\s?[A-Z]{4}\s?\d{4}\s?\d{4}\s?\d{2}$/.test(field.value.toString())
  ) {
    return false;
  }
  return true;
}

export function isSelectFieldValid(field: TFormField) {
  // console.log(field);
  // if (field.inputType === InputType.SELECT) {
  //   if (field.value) {
  //     console.log(field.value);
  //     // console.log(/^\d+$/.test("2024"));
  //     console.log(
  //       "select value check",
  //       field.inputType === InputType.SELECT &&
  //         field.value &&
  //         /^\d+$/.test(field.value.toString())
  //     );
  //   }
  // }

  if (
    (field.inputType === InputType.SELECT &&
      field.value &&
      // !/[^1-9]d*$/.test(field.value.toString())
      !/^\d+$/.test(field.value.toString())) ||
    !field.value
  ) {
    // console.log(
    //   "SELECT FAIL VALID CHECK",
    //   "value: " + field.value,
    //   "pass regex:" + !/^\d+$/.test(field.value.toString())
    // );
    return false;
  }
  return true;
}

function isPhoneFieldValid(field: TFormField) {
  if (
    field.inputType === InputType.TEL &&
    field.value &&
    // /^\+[31]{2}\s[0-9]{1}\s[0-9]{7}[^a-z]$/i.test(field.value + "")
    // /^\(?([+]31|0031|0)-?6(\s?|-)([0-9]\s{0,3}){8}$/i.test(field.value + "")
    // /^\+[31]{2}[0-9]{8}[^a-z]$/i.test(field.value.toString())
    /^\+[31]{2}[0-9]{9}[^a-z]$/i.test(field.value.toString())
  ) {
    // console.log(
    //   "failed PHONE check",
    //   field.value.toString(),
    //   !/^\+[31]{2}[0-9]{8}$/i.test(field.value!.toString())
    // );
    return false;
  }
  return true;
}

function isPostCodeFieldValid(field: TFormField) {
  // console.log(field.fieldId);
  // console.log(
  //   field.value,
  //   field.value !== "",
  //   field.value!.toString().length === 6,
  //   !/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(field.value!.toString())
  // );
  if (
    field.inputType === InputType.API_POSTCODE &&
    field.value &&
    field.value !== "" &&
    field.value.toString().length === 6 &&
    // /([a-zA-Z]{2})?[\s-]*[0-9]{4,5}[\s-]*([a-zA-Z]{2})?/gm.test(
    !/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i.test(field.value.toString())
  ) {
    return false;
  }
  return true;
}

function isDateFieldValid(field: TFormField) {
  if (field.inputType === InputType.DATE && field.value) {
    const destructedDate = field.value.toString().split("/");
    // 20/20/2020
    // 20/12/2020
    // console.log(
    //   destructedDate,
    //   moment(
    //     destructedDate[2] + "-" + destructedDate[1] + "-" + destructedDate[0]
    //   ).format("x")
    // );
    // console.log(destructedDate, moment("2020-12-31").format("DD/MM/YYYY"));
    // console.log(destructedDate);
    const valueInMomentFormat = moment(
      destructedDate[2] + "-" + destructedDate[1] + "-" + destructedDate[0]
    ).format("x");
    // const valueInMomentFormat = +moment(field.value).format("x");
    // console.log(valueInMomentFormat, field.minValue, field.maxValue);
    if (isNaN(+valueInMomentFormat)) {
      return false;
    }
    // console.log(+valueInMomentFormat);
    if (field.minValue && field.minValue > +valueInMomentFormat) {
      // console.log("#");
      return false;
    }
    if (field.maxValue && field.maxValue < +valueInMomentFormat) {
      // console.log("#");
      return false;
    }
  }
  // console.log("is valid");
  return true;
}

// @ONGOIN PROGRESS
export function checkFieldIsValid(field: TFormField, newValue: string) {
  // console.log(field.labelText, newValue, field.isMandatory);
  if (field.isMandatory) {
    // console.log("#", field.labelText);
    if (field.minLength && field.minLength > newValue.length) {
      // console.log("#", field.minLength, newValue.length);
      return false;
    }
    if (field.maxLength && field.maxLength < newValue.length) {
      // console.log("#");
      return false;
    }
    if (field.minValue && field.minValue > +newValue) {
      // console.log("#");
      return false;
    }
    if (field.maxValue && field.maxValue < +newValue) {
      // console.log("#");
      return false;
    }
    if (!isMailFieldValid(field)) {
      // console.log("#");
      return false;
    }
    if (!isUrlFieldValid(field)) {
      // console.log("#");
      return false;
    }
    if (!isIBANFieldValid(field)) {
      // console.log("#");
      return false;
    }
    if (!isSelectFieldValid(field)) {
      // console.log("#");
      return false;
    }
    if (!isPhoneFieldValid(field)) {
      // console.log("#");
      return false;
    }
    if (!isPostCodeFieldValid(field)) {
      // console.log("#", field.fieldId);
      return false;
    }
    if (!isDateFieldValid(field)) {
      // console.log("#");
      return false;
    }
    if (field.inputType === InputType.CUSTOM_MASK && field.validationRegex) {
      const r = new RegExp(field.validationRegex);
      // console.log(field.fieldId, r.test(field.value!.toString()));
      if (r.test(field.value!.toString()) === false) {
        // console.log("# failed test", field.fieldId, field.value);
        return false;
      }
    }
    // console.log("#");
    // if (
    //   field.validationRegex &&
    //   field.validationRegex.test(field.value!.toString())
    // )
    // return true;
  } else {
    // console.log(field.value);
    if (field.value !== "" && !isUrlFieldValid(field)) {
      // @TODO: Refactor this validations to not depend on isMandatory property
      // console.log("#");
      return false;
    }
  }
  return true;
}

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const allSpecialChars = "!#$~^&%*()+-_=[]/{}|:<>?,´\\'\"";
export const allSpecialCharsForEmail = "!#$~^&%*()+=[]/{}|:<>?,´\\'\"";

export const transformDataFromApi = (
  formField: TFormField,
  apiData: string
) => {
  const transformedVal = apiData;
  if (formField.inputType === InputType.SELECT_CUSTOM) {
    console.log(transformedVal);
    const apiValAsArray = apiData.replace(" ", "").split(",");
    console.log(apiValAsArray);
    const selectedOptions = formField.options?.filter((_) => {
      if (apiValAsArray.includes(_.value.toString())) {
        return _;
      }
    });
    console.log(selectedOptions);
    return selectedOptions;
  }

  // console.log(transformedVal);

  return transformedVal;
};

/**
 * Return true if the date formed by two fields is in the range of the customDateValidation property
 * @param monthField
 * @param yearField
 * @returns
 */
export const validate2DatesFields = (
  monthField: TFormField,
  yearField: TFormField
): boolean => {
  // console.log(
  //   "#",
  //   monthField,
  //   yearField
  //   // !isNaN(+monthField.value!),
  //   // !isNaN(+yearField.value!)
  // );
  // console.log(
  //   monthField,
  //   yearField,
  //   !isNaN(+monthField.value!),
  //   !isNaN(+yearField.value!)
  // );

  // if (
  //   monthField.value !== "" &&
  //   yearField.value !== "" &&
  //   !isNaN(+monthField.value!) &&
  //   !isNaN(+yearField.value!)
  // ) {
  if (
    monthField.value !== "" &&
    yearField.value !== "" &&
    !isNaN(+monthField.value!) &&
    !isNaN(+yearField.value!)
  ) {
    const monthCode = monthList.filter((el) => el.Id === +monthField.value!)[0]
      .Code;

    const formedDate = moment(
      yearField.value + "-" + monthCode + "-01",
      "YYYY-MM-DD"
    ).unix();
    // console.log("formedDate", formedDate);

    if (
      monthField.customDateValidation!.combinationMinValue <= formedDate &&
      formedDate <= monthField.customDateValidation!.combinationMaxValue
    ) {
      return true;
    }
  }

  return false;
};

/**
 * Function to detect if is iOS (iphone or mac)
 * @returns booelan
 */
export function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}
