import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import logoLeasing from "./Assets/Images/logo-keurmerk-lease.png";
import { AppContext } from "./Contexts/AppContext";
import { colorAdjust } from "./helpers";
import LandingPage from "./LandingPage";
import WizardPage from "./Pages/Wizard";
import ThanksPage from "./Pages/ThanksPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

interface IStyleProps {
  attr: string;
  val: string;
}

function App() {
  const appContext = useContext(AppContext);
  const [searchParams] = useSearchParams();

  function setThemeAttrs() {
    const styleProps: IStyleProps[] = [
      {
        attr: "--bg-color",
        val: appContext.themeProperties["BackgroundColor" as keyof unknown]
      },
      {
        attr: "--form-bg-color",
        val: appContext.themeProperties["FormBackgroundColor" as keyof unknown]
      },
      {
        attr: "--primary-color",
        val: appContext.themeProperties["PrimaryColor" as keyof unknown]
      },
      {
        attr: "--secondary-color",
        val: appContext.themeProperties["SecondaryColor" as keyof unknown]
      },
      {
        attr: "--inactive-color",
        val: appContext.themeProperties["GreyColor" as keyof unknown]
      },
      {
        attr: "--next-button-color",
        val: appContext.themeProperties["ButtonColor" as keyof unknown]
      },
      {
        attr: "--next-button-border-color",
        val: appContext.themeProperties["ButtonBorderColor" as keyof unknown]
      },
      {
        attr: "--back-button-color",
        val: appContext.themeProperties["ButtonBackColor" as keyof unknown]
      },
      {
        attr: "--back-button-border-color",
        val: appContext.themeProperties[
          "ButtonBackBorderColor" as keyof unknown
        ]
      },
      {
        attr: "--button-border-radius",
        val:
          appContext.themeProperties["ButtonRoundingTopLeft" as keyof unknown] +
          "px " +
          appContext.themeProperties[
            "ButtonRoundingTopRight" as keyof unknown
          ] +
          "px " +
          appContext.themeProperties[
            "ButtonRoundingBottomRight" as keyof unknown
          ] +
          "px " +
          appContext.themeProperties[
            "ButtonRoundingBottomLeft" as keyof unknown
          ] +
          "px"
      },
      {
        attr: "--button-padding",
        val:
          appContext.themeProperties["ButtonPaddingTop" as keyof unknown] +
          "px " +
          appContext.themeProperties["ButtonPaddingRight" as keyof unknown] +
          "px " +
          appContext.themeProperties["ButtonPaddingBottom" as keyof unknown] +
          "px " +
          appContext.themeProperties["ButtonPaddingLeft" as keyof unknown] +
          "px "
      },
      {
        attr: "--label-color",
        val: appContext.themeProperties["LabelColor" as keyof unknown]
      },
      {
        attr: "--label-weight",
        val:
          appContext.themeProperties["LabelFontWeight" as keyof unknown] == 2
            ? "bold"
            : "normal"
      },
      {
        attr: "--legend-color",
        val: appContext.themeProperties["LegendColor" as keyof unknown]
      },
      {
        attr: "--legend-weight",
        val:
          appContext.themeProperties["LegendFontWeight" as keyof unknown] == 2
            ? "bold"
            : "normal"
      },
      {
        attr: "--secondary-color",
        val: appContext.themeProperties["SecondaryColor" as keyof unknown]
      },

      // Original rules bellow
      {
        attr: "--btn-color",
        val: appContext.themeProperties["LabelColor" as keyof unknown]
      },
      {
        attr: "--btn-bg",
        val: appContext.themeProperties["SecondaryColor" as keyof unknown]
      },
      {
        attr: "--btn-hover-active-bg",
        val: appContext.themeProperties["PrimaryColor" as keyof unknown]
      },
      {
        attr: "--btn-primary-color",
        val: appContext.themeProperties["BackgroundColor" as keyof unknown]
      },
      {
        attr: "--btn-primary-bg",
        val: appContext.themeProperties["PrimaryColor" as keyof unknown]
      },
      {
        attr: "--btn-primary-hover-active-bg",
        val: colorAdjust(
          appContext.themeProperties["PrimaryColor" as keyof unknown],
          -50
        )
      },
      {
        attr: "--bg-image",
        val:
          "url(" +
          appContext.themeProperties["BackgroundImage" as keyof unknown] +
          ")"
      }
    ];
    styleProps.map((style) => {
      document.documentElement.style.setProperty(style.attr, style.val);
    });
  }
  const formTypeStr = appContext.prefilledData.FormType.toLowerCase();
  useEffect(() => {
    //Do set theme
    setThemeAttrs();
    // const a = Object.keys(appContext.themeProperties).filter((prop) => {
    //   // console.log(prop==='');
    //   return prop === "PrimaryColor";
    // });
    // console.log(appContext.themeProperties["PrimaryColor" as keyof unknown]);
    // const a = Object.keys(appContext.themeProperties).find((value: any) => {
    // console.log(value, index);
    // appContext.themeProperties[index] === "PrimaryColor";
    // return appContext.themeProperties["PrimaryColor" as keyof unknown];
    // });
    // console.log(a);
  }, [appContext.themeProperties]);

  return (
    <div className="app-container position-relative ">
      <div className="app-logo d-none d-sm-block">
        <img src={appContext.themeProperties.LogoImage} alt={"Santander NL"} />
      </div>
      <div className="app-content position-relative">
        <Routes>
          {/* <Route path={"/"} element={<LandingPage />} /> */}
          {/* <Route
            index
            path={":formType/step/:step/:subStep"}
            element={<WizardPage />}
          /> */}
          <Route index path={"/step/:step/:subStep"} element={<WizardPage />} />
          <Route
            path="*"
            element={
              <Navigate
                to={"/step/1/1?guid=" + searchParams.get("guid")}
                replace
              />
            }
          />
          <Route path={"/thank-you"} element={<ThanksPage />} />
          {/* <Route
            path={"/privacy-policy?guid=" + searchParams.get("guid")}
            element={<PrivacyPolicy />}
          /> */}
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
        </Routes>
      </div>
      <footer className="">
        <div className="container">
          <div className="row align-items-center pb-3">
            <div className="col-3">
              {formTypeStr == "b2c" ? (
                <img
                  src={logoLeasing}
                  className="img-fluid"
                  alt="Keurmerk - Private Lease"
                />
              ) : null}
            </div>
            <div className="col-6 text-center">
              <a
                href={"/privacy-policy?guid=" + searchParams.get("guid")}
                className="link-privacy"
              >
                Privacy policy
              </a>
            </div>
            <div className="col-3"></div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
