import { useLocation } from "react-router-dom";
import ThankYouImage from "../Assets/Images/thank-you-icon.png";
import StepIcon from "../Assets/Images/step-icon.svg";
import { useContext } from "react";
import { AppContext } from "../Contexts/AppContext";

const PrivacyPolicy = () => {
  const appContext = useContext(AppContext);

  // console.log(appContext.themeProperties.PrivacyPolicyContent);

  return (
    <div className="container bg-white rounded p-5 my-5 text-center shadow-lg ">
      <h1>Privacy Policy</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: appContext.themeProperties.PrivacyStatement_BE
        }}
      >
        {/* {appContext.themeProperties.PrivacyPolicyContent
          ? appContext.themeProperties.PrivacyPolicyContent
          : ""} */}
      </p>
      {/* <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac
        fermentum mi. Nulla vitae nunc sed purus porta fermentum. Nulla
        bibendum, mauris varius interdum suscipit, purus nisi varius elit, ac
        sodales arcu ligula nec risus. Cras feugiat nec massa ac maximus. Nunc
        volutpat quam nibh, sed fermentum sem pulvinar non. Pellentesque sit
        amet turpis nunc. Vestibulum molestie, massa vel tempus venenatis, justo
        urna pretium nibh, a rutrum ex diam eget ipsum. Duis vel urna quis metus
        commodo sodales et sed mauris. Sed varius nisl commodo lobortis auctor.
        Nullam viverra volutpat tincidunt. Praesent elementum arcu vel elit
        ornare laoreet. Nam luctus, enim ut ultrices pretium, lacus quam maximus
        nulla, vel laoreet arcu urna a lorem.
      </p> */}
    </div>
  );
};

export default PrivacyPolicy;
