import { IFieldDataOptions } from "../Types/FieldDataConfig";

export const monthList = [
  {
    Id: 12,
    Label: "January",
    Label_NL: "Januari",
    Order: 1,
    Is_Active: true,
    Code: 1
  },
  {
    Id: 4,
    Label: "February",
    Label_NL: "Februari",
    Order: 2,
    Is_Active: true,
    Code: 2
  },
  {
    Id: 8,
    Label: "March",
    Label_NL: "Maart",
    Order: 3,
    Is_Active: true,
    Code: 3
  },
  {
    Id: 9,
    Label: "April",
    Label_NL: "April",
    Order: 4,
    Is_Active: true,
    Code: 4
  },
  {
    Id: 11,
    Label: "May",
    Label_NL: "Mei",
    Order: 5,
    Is_Active: true,
    Code: 5
  },
  {
    Id: 5,
    Label: "June",
    Label_NL: "Juni",
    Order: 6,
    Is_Active: true,
    Code: 6
  },
  {
    Id: 3,
    Label: "July",
    Label_NL: "Juli",
    Order: 7,
    Is_Active: true,
    Code: 7
  },
  {
    Id: 2,
    Label: "August",
    Label_NL: "Augustus",
    Order: 8,
    Is_Active: true,
    Code: 8
  },
  {
    Id: 1,
    Label: "September",
    Label_NL: "September",
    Order: 9,
    Is_Active: true,
    Code: 9
  },
  {
    Id: 6,
    Label: "October",
    Label_NL: "Oktober",
    Order: 10,
    Is_Active: true,
    Code: 10
  },
  {
    Id: 10,
    Label: "November",
    Label_NL: "November",
    Order: 11,
    Is_Active: true,
    Code: 11
  },
  {
    Id: 7,
    Label: "December",
    Label_NL: "December",
    Order: 12,
    Is_Active: true,
    Code: 12
  }
];

export const getMonthList = (): IFieldDataOptions[] => {
  return monthList.map((i) => {
    return {
      label: i.Label_NL,
      value: i.Id
    };
  });
};
