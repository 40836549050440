export enum InputType {
  TEXT = "text",
  IBAN = "iban",
  EMAIL = "email",
  URL = "url",
  PASSWORD = "password",
  NUMBER = "number",
  DATE = "date",
  MONTH = "month",
  RADIO = "radio",
  SELECT = "select",
  SELECT_CUSTOM = "select-custom",
  HIDDEN = "hidden",
  TEST = "test",
  // Custom fields
  TEL = "tel",
  API_POSTCODE = "post-code",
  API_HOUSENUMBER = "house-number",
  TOGGLE_ADD_FIELD_BUTTON = "toggle-add-field-button",
  CUSTOM_MASK = "custom-mask",
  CUSTOM_TITLE = "custom-title",
  CUSTOM_HINT = "custom-hint",
  EMPTY_BLOCK = "empty-block"
}

export const addressTypes = [InputType.API_HOUSENUMBER, InputType.API_POSTCODE];
