import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AppContext } from "../Contexts/AppContext";
import { WizardContext } from "../Contexts/WizardContext";
import {
  getB2BSubSteps,
  getB2CSteps,
  getB2CSubSteps
} from "../Store/WizardController";
import { IFieldDataConfig } from "../Types/FieldDataConfig";
import WizardStep from "./WizardStep";

export type TFormField = IFieldDataConfig;

export type TSubStep = {
  groupTitle: string;
  apiName: string;
  belongsToStep: number;
};

export interface IStepNavigation {
  step: number;
  subStep: number;
}

const WizardCmp = () => {
  const { step, subStep: subStep } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formGuid, setFormGuid] = useState("");
  // const [wizardProgress, setWizardProgress] = useState<IStepNavigation>();
  const [wizardProgress, setWizardProgress] = useState<IStepNavigation>({
    step: +step!,
    subStep: +subStep!
  });

  const appContext = useContext(AppContext);
  const wizardContext = useContext(WizardContext);

  const [formTypeObj, setFormTypeObj] = useState("");

  useEffect(() => {
    setFormTypeObj(appContext.prefilledData.FormType.toLowerCase());
  }, []);
  const [isLoading, setisLoading] = useState(true);
  const [isFormFinished, setIsFormFinished] = useState(false);

  // const [allFieldSteps, setAllFieldSteps] = useState([]);

  // const [subStepObj, setSubStepObj] = useState<TSubStep>(getB2CSubSteps()[0]);
  const [subStepObj, setSubStepObj] = useState<TSubStep>();

  useEffect(() => {
    const formTypeStr = appContext.prefilledData.FormType.toLowerCase();
    setFormTypeObj(formTypeStr);
    setSubStepObj(filterSubSteps(formTypeStr, +step!)[+subStep! - 1]);
    setisLoading(false);
  }, []);

  useEffect(() => {
    // Do update field values to API result
    setFormGuid(searchParams.get("guid")!);

    if (appContext.prefilledData.IsFinished === true) {
      // goToThankYouPage();
      setIsFormFinished(true);
    }
  }, []);

  useEffect(() => {
    goToThankYouPage();
  }, [isFormFinished]);

  const updateFieldValues = async () => {
    // console.log(appContext);
  };

  function filterSubSteps(formType: string, step: number) {
    if (formType === "b2b") {
      return getB2BSubSteps().filter((_step) => _step.belongsToStep === step);
    }
    return getB2CSubSteps().filter((_step) => _step.belongsToStep === step);
  }

  function stepCalculation(moveSteps: number) {
    const _subSteps = filterSubSteps(formTypeObj, wizardProgress.step);

    // console.log("wizardProgress.step", wizardProgress.step);
    // console.log("wizardProgress.subStep", wizardProgress.subStep);
    // console.log("moveSteps", moveSteps);
    if (wizardProgress.subStep + moveSteps <= 0) {
      // console.log("here", wizardProgress.step + moveSteps >= 1);
      if (wizardProgress.step + moveSteps >= 1) {
        // console.log("1");
        // return wizardProgress.step + moveSteps;
        return wizardProgress.step - 1;
      } else {
        // console.log("2");
        return 1;
      }
    } else if (wizardProgress.subStep + moveSteps > _subSteps.length) {
      if (wizardProgress.step >= getB2CSteps().length) {
        return getB2CSteps().length;
      } else {
        return wizardProgress.step + 1;
      }
    } else {
      return wizardProgress.step;
    }
  }

  function subStepCalculation(moveSteps: number) {
    // console.log("moveSubSteps: ", moveSteps);
    // console.log("wizardProgress: ", wizardProgress);

    const _estimatedNextSubStep = wizardProgress.subStep + moveSteps;

    // console.log("_estimatedNextSubStep", _estimatedNextSubStep);

    // If in first step at all, stay there
    if (wizardProgress.step === 1 && _estimatedNextSubStep < 1) {
      return 1;
    }
    // if in first substep other than step 1
    else if (wizardProgress.step > 1 && _estimatedNextSubStep < 1) {
      return (
        filterSubSteps(formTypeObj, wizardProgress.step - 1).length +
        moveSteps +
        1
      );
    }
    // Next subStep is higher than the maximun length, then go to subStep 1 of next step
    else if (
      _estimatedNextSubStep >
      filterSubSteps(formTypeObj, wizardProgress.step).length
    ) {
      return 1;
    }
    //
    else {
      return _estimatedNextSubStep;
    }
  }

  /**
   * Return true if step should be skipped
   */
  const checkStepConditionalsAndItIsMatch = useCallback(
    (moveSteps: number) => {
      const isForward = Math.sign(moveSteps) > 0 ? true : false;
      // console.log(wizardContext.formFields[10].value);
      const isSingle = ![2, 3, 4].includes(
        +wizardContext.formFields[10].value!
      );

      // console.log("#", formTypeObj, subStepObj);
      if (formTypeObj === "b2c" && subStepObj) {
        // console.log("#");
        if (isSingle) {
          // console.log("is single");
          if (isForward) {
            if (
              [2, 4, 6].includes(
                getB2CSubSteps().findIndex(
                  (el: any) => el.groupTitle === subStepObj.groupTitle
                )
              )
            ) {
              return true;
            }
          } else if (!isForward) {
            if (
              [4, 6, 8].includes(
                getB2CSubSteps().findIndex(
                  (el: any) => el.groupTitle === subStepObj.groupTitle
                )
              )
            ) {
              // console.log(
              //   "going from step 4, 6 or 8 - Do checks to step 1 fields: 'marital status'"
              // );
              return true;
            }
          }
        }
      }

      return false;
    },
    [wizardContext, formTypeObj, subStepObj]
  );

  function checkIfIsFormFinished(moveSteps: number) {
    // console.log(formTypeObj);

    const isForward = Math.sign(moveSteps) === 1 ? true : false;

    if (formTypeObj === "b2c" && subStepObj) {
      const currentIndex = getB2CSubSteps().findIndex(
        (el) => el.apiName === subStepObj.apiName
      );

      const stepConditionalIsSingle = [1].includes(
        +wizardContext.formFields[10].value!
      );

      // console.log("stepConditional", stepConditionalIsSingle);
      // console.log("currentIndex", currentIndex);
      // console.log("getB2CSubSteps().length", getB2CSubSteps().length);

      if (stepConditionalIsSingle && currentIndex === 6 && isForward) {
        return true;
      } else if (!stepConditionalIsSingle && currentIndex === 7 && isForward) {
        return true;
      }

      // if (getB2CSubSteps().length - 1 === currentIndex) {
      //   return true;
      // }
    } else if (formTypeObj === "b2b" && subStepObj) {
      const currentIndex = getB2BSubSteps().findIndex(
        (el) => el.apiName === subStepObj.apiName
      );

      // console.log(subStepObj, getB2BSubSteps().length, currentIndex);
      // if (getB2BSubSteps().length === currentIndex) {
      //   return true;
      // }
      // console.log(currentIndex, isForward);
      if (currentIndex === 2 && isForward) {
        // console.log(currentIndex, isForward);
        return true;
      }
    }

    return false;
  }

  function handleNextStep(moveSteps: number) {
    // console.log("isForm finished:", checkIfIsFormFinished());

    // Check if the form is NOT finished
    // console.log(moveSteps);
    if (!checkIfIsFormFinished(moveSteps)) {
      const calculatedMoveSteps = checkStepConditionalsAndItIsMatch(moveSteps)
        ? moveSteps + Math.sign(moveSteps)
        : moveSteps;

      // console.log(calculatedMoveSteps);

      const nextStep: IStepNavigation = {
        step: stepCalculation(calculatedMoveSteps),
        subStep: subStepCalculation(calculatedMoveSteps)
      };

      navigate(
        "/step/" + nextStep.step + "/" + nextStep.subStep + "?guid=" + formGuid
      );

      setWizardProgress(() => {
        setSubStepObj(
          filterSubSteps(formTypeObj, nextStep.step)[nextStep.subStep - 1]
        );
        return nextStep;
      });
    }
    // Check if the form is FINISHED
    else {
      // console.log("is form finished");
      // console.log(formTypeObj);
      // let nameToShow: any;
      // if (formTypeObj === "b2c") {
      //   // console.log(
      //   //   wizardContext.formFields.filter(
      //   //     (el) => el.fieldId === "Main_PersonalData-LastName"
      //   //   )[0].value
      //   // );
      //   nameToShow = wizardContext.formFields.filter(
      //     (el) => el.fieldId === "Main_PersonalData-LastName"
      //   )[0].value;
      // } else {
      //   nameToShow = wizardContext.formFields.filter(
      //     (el) => el.fieldId === "BusinessInformation-CompanyName"
      //   )[0].value;
      // }
      // navigate("/thank-you", {
      //   state: { name: nameToShow }
      // });
      goToThankYouPage();
    }
  }

  function goToThankYouPage() {
    let nameToShow: any;
    // console.log(formTypeObj);
    if (["b2b", "b2c"].includes(formTypeObj.toLowerCase())) {
      if (formTypeObj === "b2c") {
        nameToShow = wizardContext.formFields.filter(
          (el) => el.fieldId === "Main_PersonalData-LastName"
        )[0].value;
      } else if (formTypeObj === "b2b") {
        nameToShow = wizardContext.formFields.filter(
          (el) => el.fieldId === "BusinessInformation-CompanyName"
        )[0].value;
      }

      // console.log("is form finished");

      navigate("/thank-you?guid=" + searchParams.get("guid"), {
        state: { name: nameToShow }
      });
    } else {
      // console.log("something wrong");
    }
  }

  function onMoveStep(moveSteps: number, isFinished = false) {
    // doValidate();
    // if true, then:
    // doSubmit();
    // if true, then go next step
    if (!isFinished) {
      handleNextStep(moveSteps);
    } else {
      console.log("redirect to thank you page");
      // goToThankYouPage();
    }
  }

  return (
    <div className="wizard-page">
      {!isLoading && (
        <WizardStep
          formType={formTypeObj}
          stepNumber={wizardProgress.step}
          subStepNumber={wizardProgress.subStep}
          subSteopObj={subStepObj!}
          onMoveStep={onMoveStep}
        />
      )}
    </div>
  );
};

export default WizardCmp;
