import AppForm from "./AppForm";
import { TSubStep } from "./WizardCmp";

function WizardStep(props: {
  formType: string;
  stepNumber: number;
  subStepNumber: number;
  subSteopObj: TSubStep;
  onMoveStep: (stepMove: number, isFinished: boolean) => void;
}) {
  function onMoveStep(moveSteps: number, isFinished = false) {
    props.onMoveStep(moveSteps, isFinished);
  }

  return (
    <div className="wizard-step">
      <AppForm
        data={{
          currentSubStep: props.subSteopObj,
          formType: props.formType,
          onMoveStep
        }}
      />
    </div>
  );
}

export default WizardStep;
