import { IFieldDataOptions } from "../Types/FieldDataConfig";
import {
  getCountryListApiCall,
  getOthersNetIncomeListApiCall
} from "./ApiCalls";

export const getIdTypeList = (): IFieldDataOptions[] => {
  return [
    {
      label: "Identiteitskaart",
      value: 3
    },
    {
      label: "Paspoort",
      value: 4
    },
    {
      label: "Rijbewijs",
      value: 1
    },
    {
      label: "Verblijfsvergunning",
      value: 2
    }
  ];
};

export const getTypeOfHouseList = (): IFieldDataOptions[] => {
  return [
    // Entity: TypeOfHouse?
    {
      label: "Huur",
      value: 2
    },
    {
      label: "Koop",
      value: 1
    },
    {
      label: "Inwoned",
      value: 3
    }
  ];
};

export const getIncomePeriodList = (): IFieldDataOptions[] => {
  return [
    {
      label: "Wekelijks",
      value: 4
    },
    {
      label: "4 Wekelijks",
      value: 1
    },
    {
      label: "Maandelijks",
      value: 3
    },
    {
      label: "Jaarlijks",
      value: 2
    }
  ];
};

export const getMaritalStatusList = (): IFieldDataOptions[] => {
  return [
    {
      label: "Gehuwd",
      value: 3
    },
    {
      label: "Geregistreerd partner",
      value: 4
    },
    {
      label: "Alleenstaand",
      value: 1
    },
    {
      label: "Samenwonend",
      value: 2
    }
  ];
};

export const getTypeOfResidencePermit = (): IFieldDataOptions[] => {
  return [
    // @TODO: Set the right values
    {
      label: "Type I",
      value: 1
    },
    {
      label: "Type II",
      value: 2
    },
    {
      label: "Type III",
      value: 3
    },
    {
      label: "Type IV",
      value: 4
    },
    {
      label: "Type V",
      value: 5
    },
    {
      label: "Type EU/EER",
      value: 6
    }
  ];
};

export const getEmploymentTypeList = (): IFieldDataOptions[] => {
  return [
    {
      label: "Uitkering",
      value: 1
    },
    {
      label: "Pensioen",
      value: 2
    },
    {
      label: "Loondienst",
      value: 4
    },
    {
      label: "Uitzendkracht",
      value: 8
    },
    {
      label: "Zelfstandige",
      value: 10
    },
    {
      label: "Geen inkomen",
      value: 12
    }
  ];
};

export const getBenefitTypeList = (): IFieldDataOptions[] => {
  return [
    // entity BenefitTypes?
    // @TODO: set the right values
    {
      label: "IVA",
      value: 13
    },
    {
      label: "WGA",
      value: 12
    },
    {
      label: "ANW",
      value: 11
    },
    {
      label: "Wajong",
      value: 10
    },
    {
      label: "WAO",
      value: 7
    },
    {
      label: "WAZO",
      value: 9
    },
    {
      label: "ABW",
      value: 4
    },
    {
      label: "ANW/AWW",
      value: 6
    },
    {
      label: "ROA",
      value: 1
    }
  ];
};

export const getPhaseList = (): IFieldDataOptions[] => {
  return [
    // @TODO: set the right values
    {
      label: "A",
      value: 1
    },
    {
      label: "B",
      value: 2
    },
    {
      label: "C",
      value: 3
    },
    {
      label: "F",
      value: 4
    },
    {
      label: "H",
      value: 5
    },
    {
      label: "Y",
      value: 6
    }
  ];
};

export const getLegalEntityList = (): IFieldDataOptions[] => {
  return [
    // Entity LegalEntityB2B
    // @TODO: Check figma options at step 1 "Commanditaire vennootschap (CV) Maatschap" doesn't exists on DB "Maatschap" word is another option, also missing "Overig" at options
    {
      label: "Besloten vennootschap (BV)",
      value: 7
    },
    {
      label: "Naamloze vennootschap (NV)",
      value: 4
    },
    {
      label: "Vereniging",
      value: 5
    },
    {
      label: "Cooeratie",
      value: 10
    },
    {
      label: "Stichting",
      value: 3
    },
    {
      label: "Eenmanszaak",
      value: 9
    },
    {
      label: "Vennootschap onder firma (Vof)",
      value: 6
    },
    {
      label: "Commanditaire vennootschap (CV) Maatschap",
      value: 2
    }
  ];
};

export const yesOrNoList = (): IFieldDataOptions[] => {
  return [
    {
      label: "Ja",
      value: 1
    },
    {
      label: "Nee",
      value: 2
    }
  ];
};

export const getOthersNetIncomeList = async () => {
  return getOthersNetIncomeListApiCall();
};

export const getCountryList = async () => {
  return getCountryListApiCall();
};
