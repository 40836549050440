import { Spinner as SpinnerBs } from "react-bootstrap";

export default function Spinner() {
  return (
    <div className="d-flex justify-content-center align-items-center w-100 vh-100">
      <SpinnerBs animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </SpinnerBs>
    </div>
  );
}
