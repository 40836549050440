import { InputType } from "../Types/InputType";
import { TFormField } from "../Components/WizardCmp";
import { defaultDateFormat, range } from "../helpers";
import {
  ConditionalOperator,
  IFieldCountriesDataOptions
} from "../Types/FieldDataConfig";
import moment from "moment";
import { getMonthList } from "./MonthList";
import { v4 as uuidv4 } from "uuid";
import {
  getBenefitTypeList,
  getCountryList,
  getEmploymentTypeList,
  getIdTypeList,
  getIncomePeriodList,
  getLegalEntityList,
  getMaritalStatusList,
  getOthersNetIncomeList,
  getPhaseList,
  getTypeOfHouseList,
  getTypeOfResidencePermit,
  yesOrNoList
} from "./SelectOptionsStore";

// let myuuid = uuidv4();

export const getB2CSteps = () => {
  return [
    "Persoonlijke gegevens",
    "Beroepsgegevens/Werk",
    "Financiële gegevens"
    // "Thank you page",
  ];
};

export const getB2BSteps = () => {
  return ["Bedrijfsinformatie", "Contact persoon", "Directors"];
};

// const a = getCountryList();
// console.log(a);

export const getB2CSubSteps = () => {
  return [
    {
      groupTitle: "Persoonsgegevens",
      apiName: "Main_PersonalData",
      belongsToStep: 1
    },
    {
      groupTitle: "Adres",
      apiName: "Main_AddressData",
      belongsToStep: 1
    },
    {
      groupTitle: "Identificatie",
      apiName: "Main_IdentificationData",
      belongsToStep: 1
    },
    {
      groupTitle: "Persoonsgegevens - Partner",
      apiName: "Partner_PersonalData",
      belongsToStep: 1
    },
    {
      groupTitle: "Beroepsgegevens",
      apiName: "Main_EmploymentData",
      belongsToStep: 2
    },
    {
      groupTitle: "Beroepsgegevens - Partner",
      apiName: "Partner_EmploymentData",
      belongsToStep: 2
    },
    {
      groupTitle:
        "<p>Financiële gegevens</p><div class='fs-6 pr-2'><i class='bi bi-info-circle-fill pe-2 ps-2'></i><b>Round de cijfers achter de komma naar boven af</b></div>",
      apiName: "Main_FinancialData",
      belongsToStep: 3
    },
    {
      groupTitle: "Financiële gegevens - Partner",
      apiName: "Partner_FinancialData",
      belongsToStep: 3
    }
  ];
};

export const getB2BSubSteps = () => {
  return [
    {
      groupTitle: "Bedrijfsinformatie",
      apiName: "BusinessInformation",
      belongsToStep: 1
    },
    {
      groupTitle: "Contact persoon",
      apiName: "ContactPerson",
      belongsToStep: 2
    },
    {
      groupTitle: "Directors",
      apiName: "Directors",
      belongsToStep: 3
    }
  ];
};

// const testConditionalIdField = uuidv4();
// const testConditionalIdField2 = uuidv4();

const totJaar2MonthMainFieldId = uuidv4();
const totJaar2YearMainFieldId = uuidv4();
const totJaar2MonthPartnerFieldId = uuidv4();
const totJaar2YearPartnerFieldId = uuidv4();

export const getAllB2CFormFields = async (): Promise<TFormField[]> => {
  // return [

  // const nationalityList = await getCountryList();

  // console.log("nationalityList", nationalityList.length);

  const a: TFormField[] = [
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-Sex",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "",
      labelHint: "Maak a.u.b. een keuze zoals in het ID staat vermeld.",
      value: 1,
      inputType: InputType.RADIO,
      options: [
        {
          label: "Man",
          value: 1
        },
        {
          label: "Vrouw",
          value: 2
        }
      ],
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-row-reverse"
      },
      displayOneLine: true,
      isMandatory: true
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-Initials",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Voorletters",
      labelHint: "Vermeld alle voorletters zoals in het ID staat vermeld.",
      inputType: InputType.TEXT,
      minLength: 1,
      maxLength: 5,
      isMandatory: true,
      // validationRegex: "^[a-zA-Z]{1,5}$",
      // validationRegex: /^[a-zA-Z]{1,5}$/,
      // validationRegex: /^(?=.*?[A-Za-z])[A-Za-z+]+$/,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoWhiteSpaces: true,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-FirstName",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Voornaam",
      labelHint: "Vermeld uw eerste naam of voornaam.",
      inputType: InputType.TEXT,
      minLength: 1,
      maxLength: 50,
      isMandatory: true,
      // validationRegex: "^[a-zA-Z]{1,50}$",
      // validationRegex: /^[a-zA-Z]+$/,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-Prefix",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Tussenvoegsel (optioneel)",
      inputType: InputType.TEXT,
      maxLength: 10,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      // validationRegex: "^[a-zA-Z]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-LastName",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Achternaam",
      labelHint: "Wij hebben de geboortenaam ofwel meisjesnaam nodig.",
      inputType: InputType.TEXT,
      minLength: 1,
      maxLength: 50,
      isMandatory: true,
      // validationRegex: "^[a-zA-Z]{1,50}$",
      // validationRegex: /[^a-zA-Z\s]$/,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-BirthDate",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Geboortedatum",
      labelHint: "Jonger dan 18 of ouder dan 80 is niet toegestaan.",
      inputType: InputType.DATE,
      // inputType: InputType.CUSTOM_MASK,
      // inputPlaceholder: "01/01/2000",
      // visualRules: {
      //   hardInvisibility: false,
      //   // mask: /(([012][0-9])|(3[01]))\/([0]{0,1}[1-9]|1[012])\/\d{4}/gim
      //   mask: "01/01/2000"
      // },
      isMandatory: true,
      minValue: +moment().subtract(80, "year").format("x"), //(today-filledDate = 80 years)
      maxValue: +moment().subtract(18, "year").format("x"), //(today-filledDate = 18 years),
      validationMessage:
        "Uw leeftijd mag niet lager dan 18 zijn of hoger dan 80."
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-MobileTelephoneNumber",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Telefoonnummer",
      labelHint: "Let op dat het telefoonnummer minimaal 10 cijfers bevat.",
      inputType: InputType.TEL,
      isMandatory: true,
      minLength: 12, // It's 13 plus 2 spaces resulting 15 in total
      maxLength: 15, // It's 13 plus 2 spaces resulting 15 in total
      // validationRegex: /^\+[31]{2}\s[0-9]{1}\s[0-9]{7}[^a-z]$/i,
      // validationRegex: /^\+[31]{2}[0-9]{8}[^a-z]$/i,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-EmailAddress",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "E-mailadres",
      inputType: InputType.EMAIL,
      isMandatory: true,
      maxLength: 50,
      validationRegex: "^.+@.+.[A-Za-z]+$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-Nationality",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Nationaliteit",
      inputType: InputType.SELECT,
      // value: 1,
      options: await getCountryList(),
      isMandatory: true,
      validationRegex: "^[1-9]d*$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-IBANNumber",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "IBAN",
      labelHint: "Vul uw volledige IBAN (bankrekeningnummer) in.",
      inputType: InputType.IBAN,
      isMandatory: true,
      maxLength: 50,
      // validationRegex: "^NL[0-9]{2}[A-z0-9]{4}[0-9]{10}$",
      // validationRegex: /^NL[0-9]{2}[A-Za-z0-9]{4}[0-9]{10}$/gim,
      // validationRegex: /[^a-zA-Z]+/,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-MaritalStatus",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Burgerlijke staat",
      isMandatory: true,
      inputType: InputType.SELECT,
      options: getMaritalStatusList(),
      validationRegex: "^[1-9]d*$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_PersonalData-ChildrenLivingAtHome",
      belongsToStep: getB2CSubSteps()[0].groupTitle,
      labelText: "Thuiswonende kinderen",
      labelHint: "Vermeld het aantal inwonende kinderen jonger dan 18 jaar.",
      // inputType: InputType.TEST,
      inputType: InputType.NUMBER,
      isMandatory: true,
      minLength: 1,
      maxLength: 2,
      minValue: 0,
      maxValue: 99,
      // validationRegex: "^[0-9]{1,2}/gim$",
      validationRegex: /^[0-9]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },

    // Form Group 2 - Address

    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-Postcode",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Postcode",
      inputType: InputType.API_POSTCODE,
      minLength: 6,
      isMandatory: true,
      maxLength: 6,
      // validationRegex: "!/([a-zA-Z]{2})?[s-]*[0-9]{4,5}[s-]*([a-zA-Z]{2})?/gm", //@TODO: Check if works
      validationNoWhiteSpaces: true,
      validationRegex: /^[1-9]{1}[0-9]{3} ?(?!sa|sd|ss)[a-zA-Z]{2}$/gim, //@TODO: Check if works
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-HouseNumber",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Huisnummer",
      inputType: InputType.API_HOUSENUMBER,
      minLength: 1,
      isMandatory: true,
      maxLength: 6,
      // validationRegex: "^[0-9]{1,6}$",
      // validationNoNumbers: true,
      validationNoWhiteSpaces: true,
      // validationRegex: /[^0-9]+$/,
      validationOnlyNumbers: true,
      // validationRegex: /^[0-9]+$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-HouseNumberAddition",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Toevoeging",
      inputType: InputType.TEXT,
      maxLength: 10,
      validationRegex: /[^a-zA-Z0-9]+$/gim,
      // validationRegex: "^[a-zA-Z0-9]{0,50}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-Street",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Straat",
      inputType: InputType.TEXT,
      isDisabled: true,
      filledBy: ["Main_AddressData-Postcode", "Main_AddressData-HouseNumber"],
      isMandatory: true,
      maxLength: 50,
      validationRegex: "^[a-zA-Z]{1,50}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-City",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Plaats",
      inputType: InputType.TEXT,
      isDisabled: true,
      filledBy: ["Main_AddressData-Postcode", "Main_AddressData-HouseNumber"],
      isMandatory: true,
      maxLength: 50,
      validationRegex: "^[a-zA-Z]{1,50}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-LivingSinceMonth",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Woonachtig sinds",
      inputType: InputType.SELECT,
      // maxValue: +moment().format("x"), // unix timestamp - today
      // options: await getMonthList(),
      options: getMonthList(),
      isMandatory: true,
      fieldColumnsWidth: 3,
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_AddressData-LivingSinceYear",
        isMonth: true,
        combinationMinValue: +moment().subtract(50, "year").unix(),
        combinationMaxValue: +moment().unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-LivingSinceYear",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Woonachtig sinds",
      labelHint:
        "Vul in vanaf welke maand en jaar u op uw huidige adres woont.",
      inputType: InputType.SELECT,
      // maxValue: +moment().format("x"), // unix timestamp - today
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() - 50,
        -1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      isMandatory: true,
      fieldColumnsWidth: 3,
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_AddressData-LivingSinceMonth",
        isMonth: false,
        // combinationMinValue: +moment().subtract(1, "year").format("x"),
        // combinationMaxValue: +moment().format("x")
        combinationMinValue: +moment().subtract(50, "year").unix(),
        combinationMaxValue: +moment().unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-PreviousPostcode",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Vorige postcode",
      inputType: InputType.API_POSTCODE,
      groupingArea: {
        title: "Gegevens van uw vorig adres"
      },
      conditional: {
        dependsOn: "Main_AddressData-LivingSinceMonth",
        joinField: "Main_AddressData-LivingSinceYear",
        operator: ConditionalOperator.GreatherOrEqualThan,
        value: moment().subtract(1, "year").format(defaultDateFormat)
      },
      isMandatory: true,
      validationNoWhiteSpaces: true,
      minLength: 6,
      maxLength: 6,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-PreviousHouseNumber",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Vorige huisnummer",
      inputType: InputType.API_HOUSENUMBER,
      groupingArea: {
        title: "Gegevens van uw vorig adres"
      },
      conditional: {
        dependsOn: "Main_AddressData-LivingSinceMonth",
        joinField: "Main_AddressData-LivingSinceYear",
        operator: ConditionalOperator.GreatherOrEqualThan,
        value: moment().subtract(1, "year").format(defaultDateFormat)
      },
      isMandatory: true,
      minLength: 1,
      maxLength: 6,
      validationNoWhiteSpaces: true,
      validationRegex: "^[0-9]{1,6}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-PreviousHouseNumberAddition",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Vorige toevoeging",
      inputType: InputType.TEXT,
      groupingArea: {
        title: "Gegevens van uw vorig adres"
      },
      conditional: {
        dependsOn: "Main_AddressData-LivingSinceMonth",
        joinField: "Main_AddressData-LivingSinceYear",
        operator: ConditionalOperator.GreatherOrEqualThan,
        value: moment().subtract(1, "year").format(defaultDateFormat)
      },
      // minLength: 6,
      // isMandatory: true,
      maxLength: 10
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-PreviousStreet",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Vorige straat",
      inputType: InputType.TEXT,
      isDisabled: true,
      groupingArea: {
        title: "Gegevens van uw vorig adres"
      },
      conditional: {
        dependsOn: "Main_AddressData-LivingSinceMonth",
        joinField: "Main_AddressData-LivingSinceYear",
        operator: ConditionalOperator.GreatherOrEqualThan,
        value: moment().subtract(1, "year").format(defaultDateFormat)
      },
      filledBy: [
        "Main_AddressData-PreviousPostcode",
        "Main_AddressData-PreviousHouseNumber"
      ],
      isMandatory: true
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_AddressData-PreviousCity",
      belongsToStep: getB2CSubSteps()[1].groupTitle,
      labelText: "Vorige plaats",
      inputType: InputType.TEXT,
      isDisabled: true,
      groupingArea: {
        title: "Gegevens van uw vorig adres"
      },
      conditional: {
        dependsOn: "Main_AddressData-LivingSinceMonth",
        joinField: "Main_AddressData-LivingSinceYear",
        operator: ConditionalOperator.GreatherOrEqualThan,
        value: moment().subtract(1, "year").format(defaultDateFormat)
      },
      filledBy: [
        "Main_AddressData-PreviousPostcode",
        "Main_AddressData-PreviousHouseNumber"
      ],
      isMandatory: true
    },

    // Form Group 3 - Identificatie

    {
      //For Netherlanders
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-IDType",
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      labelHint:
        "Vul de gegevens in van het ID-bewijs dat u meestuurt met de leaseaanvraag.",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => _.value !== 2),
      conditional: [
        {
          dependsOn: "Main_PersonalData-Nationality",
          value: 1,
          operator: ConditionalOperator.Equal
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For EU
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-IDType",
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      labelHint:
        "Vul de gegevens in van het ID-bewijs dat u meestuurt met de leaseaanvraag.",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => ![1, 2].includes(+_.value)),
      conditional: [
        {
          dependsOn: "Main_PersonalData-Nationality",
          value: 1,
          operator: ConditionalOperator.Different
        },
        {
          dependsOn: "Main_PersonalData-Nationality",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.InArray
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For non EU
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-IDType",
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      labelHint:
        "Vul de gegevens in van het ID-bewijs dat u meestuurt met de leaseaanvraag.",
      inputType: InputType.SELECT,
      value: 2,
      options: getIdTypeList().filter((_) => [2].includes(+_.value)),
      conditional: [
        {
          dependsOn: "Main_PersonalData-Nationality",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.NotInArray
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-TypeOfResidencePermit",
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Soort Verblijfsvergunning",
      inputType: InputType.SELECT,
      options: getTypeOfResidencePermit(),
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_IdentificationData-IDType",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-IDNumber",
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Documentnummer",
      labelHint: "Vul het nummer van het ID-bewijs in, niet het BSN.",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 30,
      // validationRegex: "^[0-9a-zA-Z]{1,30}$",
      // validationRegex: "^[0-9a-zA-Z]{1,30}$",
      validationRegex: /^[a-zA-Z0-9]+$/gim,
      validationNoWhiteSpaces: true,
      validationNoSpecialChar: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-ValidUntilMonth", // TODO: Check if will it be splited in two fields month and year instead of a date field or month field
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Geldig tot maand",
      labelHint: "Vul in tot welke datum het ID-bewijs geldig is.",
      inputType: InputType.SELECT,
      fieldColumnsWidth: 3,
      isMandatory: true,
      options: getMonthList(),
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_IdentificationData-ValidUntilYear",
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_IdentificationData-ValidUntilYear", // TODO: Check if will it be splited in two fields month and year instead of a date field or month field
      belongsToStep: getB2CSubSteps()[2].groupTitle,
      labelText: "Geldig tot jaar",
      labelHint: "Vul in tot welke datum het ID-bewijs geldig is.",
      inputType: InputType.SELECT,
      fieldColumnsWidth: 3,
      isMandatory: true,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 10,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_IdentificationData-ValidUntilMonth",
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },

    // Form Group 4 - Persoonsgegevens - Partner

    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-Sex",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "",
      labelHint: "Maak a.u.b. een keuze zoals in het ID staat vermeld.",
      value: 1,
      inputType: InputType.RADIO,
      options: [
        {
          label: "Man",
          value: 1
        },
        {
          label: "Vrouw",
          value: 2
        }
      ],
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-row-reverse"
      },
      displayOneLine: true,
      isMandatory: true
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-Initials",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Voorletters",
      labelHint: "Vermeld alle voorletters zoals in het ID staat vermeld.",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 5,
      // validationRegex: "^[a-zA-Z]{1,6}$",
      validationRegex: /[^a-zA-Z]+$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-FirstName",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Voornaam",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      // validationRegex: "^[a-zA-Z]{1,50}$",
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-Prefix",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Tussenvoegsel (optioneel)",
      inputType: InputType.TEXT,
      maxLength: 10,
      // validationRegex: "^[a-zA-Z]{1,10}$",
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-LastName",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Achternaam",
      labelHint: "Wij hebben de geboortenaam ofwel meisjesnaam nodig.",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      // validationRegex: "^[a-zA-Z]{1,50}$",
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-BirthDate",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Geboortedatum",
      labelHint: "Jonger dan 18 of ouder dan 80 is niet toegestaan.",
      inputType: InputType.DATE,
      isMandatory: true,
      minValue: +moment().subtract(80, "year").format("x"), //(today-filledDate = 80 years)
      maxValue: +moment().subtract(18, "year").format("x"), //(today-filledDate = 18 years)
      validationMessage:
        "Uw leeftijd mag niet lager dan 18 zijn of hoger dan 80."
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-Nationality",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Nationaliteit",
      inputType: InputType.SELECT,
      // value: 1,
      options: await getCountryList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-MobileTelephoneNumber",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Telefoonnummer",
      labelHint: "Let op dat het telefoonnummer minimaal 10 cijfers bevat.",
      value: "+31",
      inputType: InputType.TEL,
      minLength: 12, // It's 13 plus 2 spaces resulting 15 in total
      maxLength: 15,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-EmailAddress",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "E-mailadres",
      labelHint:
        "Uw partner krijgt zelf separaat e-mail voor bewijsdocumenten en ondertekening.",
      inputType: InputType.EMAIL,
      isMandatory: true,
      maxLength: 50,
      validationRegex: "^.+@.+.[A-Za-z]+$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-EmailAddress2", // @TODO: Put some validation to be the same value as the first
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Herhaal e-mailadres",
      inputType: InputType.EMAIL,
      confirmationFieldId: "Partner_PersonalData-EmailAddress",
      isMandatory: true,
      maxLength: 50,
      validationRegex: "^.+@.+.[A-Za-z]+$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For Netherlanders
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-IDType",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Legitimatiebewijs",
      labelHint:
        "Vul de gegevens in van het ID-bewijs dat u meestuurt met de leaseaanvraag.",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => _.value !== 2),
      conditional: [
        {
          dependsOn: "Partner_PersonalData-Nationality",
          value: 1,
          operator: ConditionalOperator.Equal
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For EU
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-IDType",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Legitimatiebewijs",
      labelHint:
        "Vul de gegevens in van het ID-bewijs dat u meestuurt met de leaseaanvraag.",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => ![1, 2].includes(+_.value)),
      conditional: [
        {
          dependsOn: "Partner_PersonalData-Nationality",
          value: 1,
          operator: ConditionalOperator.Different
        },
        {
          dependsOn: "Partner_PersonalData-Nationality",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.InArray
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For non EU
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-IDType",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Legitimatiebewijs",
      labelHint:
        "Vul de gegevens in van het ID-bewijs dat u meestuurt met de leaseaanvraag.",
      inputType: InputType.SELECT,
      value: 2,
      options: getIdTypeList().filter((_) => [2].includes(+_.value)),
      conditional: [
        {
          dependsOn: "Partner_PersonalData-Nationality",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.NotInArray
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-TypeOfResidencePermit",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Soort Verblijfsvergunning",
      inputType: InputType.SELECT,
      options: getTypeOfResidencePermit(),
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Partner_PersonalData-IDType",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-IDNumber",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Documentnummer",
      labelHint: "Vul het nummer van het ID-bewijs in, niet het BSN.",
      inputType: InputType.TEXT,
      isMandatory: true,
      maxLength: 30,
      // validationRegex: "^[0-9a-zA-Z]{1,30}$",
      validationRegex: /^[a-zA-Z0-9]+$/gim,
      validationNoWhiteSpaces: true,
      validationNoSpecialChar: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-ValidUntilMonth",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Geldig tot maand",
      labelHint: "Vul in tot welke datum het ID-bewijs geldig is.",
      fieldColumnsWidth: 3,
      inputType: InputType.SELECT,
      isMandatory: true,
      options: getMonthList(),
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Partner_PersonalData-ValidUntilYear",
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_PersonalData-ValidUntilYear",
      belongsToStep: getB2CSubSteps()[3].groupTitle,
      labelText: "Geldig tot jaar",
      labelHint: "Vul in tot welke datum het ID-bewijs geldig is.",
      fieldColumnsWidth: 3,
      inputType: InputType.SELECT,
      isMandatory: true,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 10,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Partner_PersonalData-ValidUntilMonth",
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },

    // Form Step 2 - substep 1 - Beroepsgegevens

    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-EmploymentType",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Hoofdinkomen / werksituatie",
      labelHint:
        "Geef a.u.b. aan waar het grootste deel van uw inkomen vandaan komt.",
      // value: 1,
      inputType: InputType.SELECT,
      options: getEmploymentTypeList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-EmploymentContractType",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Soort contract",
      inputType: InputType.SELECT,
      options: [
        // entity: ContractType ?
        // @TODO: SEt the right values
        {
          label: "Onbepaalde tijd",
          value: 1
        },
        {
          label: "Tijdelijk",
          value: 2
        }
      ],
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: 4
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-BenefitType",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Soort uitkering",
      labelHint: "Geef au.b. aan welke uitkering u ontvangt.",
      inputType: InputType.SELECT,
      options: getBenefitTypeList(),
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-EmployedSinceMonth",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Sinds maand",
      labelHint:
        "Vul de maand en het jaar in van de start van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      // minValue: +moment("1921-01").format("x"), // 1920
      // maxValue: +moment().format("x"), // today
      options: getMonthList(),
      visualRules: {
        hardInvisibility: true
      },
      fieldColumnsWidth: 3,
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_EmploymentData-EmployedSinceYear",
        isMonth: true,
        combinationMinValue: +moment("1920-01").unix(),
        combinationMaxValue: +moment().unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-EmployedSinceYear",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Sinds jaar",
      labelHint:
        "Vul de maand en het jaar in van de start van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      options: range(new Date().getFullYear(), 1920, -1).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      fieldColumnsWidth: 3,
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_EmploymentData-EmployedSinceMonth",
        isMonth: false,
        combinationMinValue: +moment("1920-01").unix(),
        combinationMaxValue: +moment().unix()
      }
    },
    {
      // internalId: uuidv4(),
      internalId: totJaar2MonthMainFieldId,
      fieldId: "Main_EmploymentData-EmployedUntilMonth",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Tot maand",
      labelHint:
        "Vul de maand en het jaar in van de einddatum van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      options: getMonthList(),
      isMandatory: true,
      fieldColumnsWidth: 3,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [4]
        },
        {
          dependsOn: "Main_EmploymentData-EmploymentContractType",
          operator: ConditionalOperator.Equal,
          value: [2]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        // otherFieldId: "Main_EmploymentData-EmployedUntilYear",
        otherFieldId: totJaar2YearMainFieldId,
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-EmployedUntilMonth",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Tot maand",
      labelHint:
        "Vul de maand en het jaar in van de einddatum van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      options: getMonthList(),
      isMandatory: true,
      fieldColumnsWidth: 3,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_EmploymentData-EmployedUntilYear",
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      // internalId: uuidv4(),
      internalId: totJaar2YearMainFieldId,
      fieldId: "Main_EmploymentData-EmployedUntilYear",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Tot jaar",
      isMandatory: true,
      labelHint:
        "Vul de maand en het jaar in van de einddatum van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 20,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      fieldColumnsWidth: 3,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [4]
        },
        {
          dependsOn: "Main_EmploymentData-EmploymentContractType",
          operator: ConditionalOperator.Equal,
          value: [2]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        // otherFieldId: "Main_EmploymentData-EmployedUntilMonth",
        otherFieldId: totJaar2MonthMainFieldId,
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-EmployedUntilYear",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Tot jaar",
      isMandatory: true,
      labelHint:
        "Vul de maand en het jaar in van de einddatum van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 20,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      fieldColumnsWidth: 3,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Main_EmploymentData-EmployedUntilMonth",
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-Employer",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Bedrijfsnaam",
      labelHint: "Vul a.u.b. de naam in van uw werkgever.",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      // validationRegex: "^[0-9a-zA-Z]{1,500}$",
      // validationRegex: /[^a-zA-Z\s]+$/, // only letters and space
      // validationRegex: /[^a-zA-Z\S]+$/, //only letters and numbers
      // validationRegex: /[^a-zA-Z\s]+$/gim,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-Phase",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Fase",
      inputType: InputType.SELECT,
      // value: 1,
      options: getPhaseList(),
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: 8
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-Job",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Beroep",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 25,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      // validationRegex: "^[0-9a-zA-Z]{1,25}$",
      validationRegex: /[^a-zA-Z\s]+$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-NetIncome",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Netto inkomen",
      labelHint:
        "Vul uw terugkerend nettoinkomen in dus dat wat u met regelmaat krijgt uitbetaald.",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minLength: 1,
      maxLength: 10,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-IncomePeriod",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Uitbetaling per",
      labelHint: "Geef a.u.b. aan in welke regelmaat u betaald krijgt.",
      inputType: InputType.SELECT,
      options: getIncomePeriodList(),
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-ExtraIncome",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Extra of ander inkomen",
      labelHint:
        "Heeft u naast uw hoofdinkomen nog andere regelmatig terugkerende inkomsten? Dan kunt u deze hier aangeven.",
      inputType: InputType.SELECT_CUSTOM,
      isMultiSelect: true,
      options: await getOthersNetIncomeList(),
      groupingArea: {
        title: "Extra inkomen toevoegen"
      },
      conditional: [
        {
          dependsOn: "Main_EmploymentData-HasExtraIncome",
          operator: ConditionalOperator.Equal,
          value: "1"
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-TotalAmountExtraIncome",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Totaal bedrag aan extra inkomen",
      labelHint:
        "Vul het netto totaalbedrag (opgeteld) van uw extra inkomsten in",
      inputType: InputType.NUMBER,
      minLength: 1,
      maxLength: 10,
      groupingArea: {
        title: "Extra inkomen toevoegen"
      },
      conditional: [
        {
          dependsOn: "Main_EmploymentData-HasExtraIncome",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-PaymentPer",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Uitbetaling per",
      labelHint:
        "Geef m.b.v. onderstaande keuzes aan in welke frequentie u betaald krijgt.",
      inputType: InputType.SELECT,
      options: getIncomePeriodList(),
      groupingArea: {
        title: "Extra inkomen toevoegen"
      },
      conditional: [
        {
          dependsOn: "Main_EmploymentData-HasExtraIncome",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_EmploymentData-HasExtraIncome",
      belongsToStep: getB2CSubSteps()[4].groupTitle,
      labelText: "Voeg extra inkomen toe",
      toggleText: "Annuleer",
      inputType: InputType.TOGGLE_ADD_FIELD_BUTTON,
      fieldColumnsWidth: 12,
      value: 0
      // conditional: {
      //   dependsOn: "Main_EmploymentData-HasExtraIncome",
      //   operator: ConditionalOperator.Equal,
      //   value: "0"
      // },
      // visualRules: {
      //   hardInvisibility: true
      // }
    },

    // Form Step 2 - substep 2 - Beroepsgegevens - Partner

    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-EmploymentType",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Hoofdinkomen / werksituatie",
      labelHint:
        "Geef a.u.b. aan waar het grootste deel van uw inkomen vandaan komt.",
      inputType: InputType.SELECT,
      options: getEmploymentTypeList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-EmploymentContractType",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Soort contract",
      inputType: InputType.SELECT,
      options: [
        // entity: ContractType ?
        // @TODO: SEt the right values
        {
          label: "Onbepaalde tijd",
          value: 1
        },
        {
          label: "Tijdelijk",
          value: 2
        }
      ],
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: 4
        }
      ],
      visualRules: {
        hardInvisibility: true
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-BenefitType",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Soort uitkering",
      inputType: InputType.SELECT,
      options: getBenefitTypeList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-EmployedSinceMonth",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Sinds maand",
      inputType: InputType.SELECT,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      options: getMonthList(),
      fieldColumnsWidth: 3,
      customDateValidation: {
        otherFieldId: "Partner_EmploymentData-EmployedSinceYear",
        isMonth: true,
        combinationMinValue: +moment("1920-01").unix(),
        combinationMaxValue: +moment().unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-EmployedSinceYear",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Sinds jaar",
      labelHint:
        "Vul de maand en het jaar in van de start van uw huidige arbeidscontract.",
      inputType: InputType.SELECT,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      options: range(new Date().getFullYear(), 1920, -1).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      fieldColumnsWidth: 3,
      customDateValidation: {
        otherFieldId: "Partner_EmploymentData-EmployedSinceMonth",
        isMonth: false,
        combinationMinValue: +moment("1920-01").unix(),
        combinationMaxValue: +moment().unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-Phase",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Fase",
      inputType: InputType.SELECT,
      options: getPhaseList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: 8
        }
      ],
      visualRules: {
        hardInvisibility: true
      }
    },
    {
      // internalId: uuidv4(),
      internalId: totJaar2MonthPartnerFieldId,
      fieldId: "Partner_EmploymentData-EmployedUntilMonth",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Tot maand",
      inputType: InputType.SELECT,
      options: getMonthList(),
      fieldColumnsWidth: 3,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [4]
        },
        {
          dependsOn: "Partner_EmploymentData-EmploymentContractType",
          operator: ConditionalOperator.Equal,
          value: [2]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      customDateValidation: {
        // otherFieldId: "Partner_EmploymentData-EmployedUntilYear",
        otherFieldId: totJaar2YearPartnerFieldId,
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-EmployedUntilMonth",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Tot maand",
      inputType: InputType.SELECT,
      options: getMonthList(),
      fieldColumnsWidth: 3,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      customDateValidation: {
        otherFieldId: "Partner_EmploymentData-EmployedUntilYear",
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },

    {
      // internalId: uuidv4(),
      internalId: totJaar2YearPartnerFieldId,
      fieldId: "Partner_EmploymentData-EmployedUntilYear",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Tot jaar",
      labelHint:
        "Vul de maand en het jaar in van de einddatum van uw uitkering.",
      inputType: InputType.SELECT,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 50,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      fieldColumnsWidth: 3,
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [4]
        },
        {
          dependsOn: "Partner_EmploymentData-EmploymentContractType",
          operator: ConditionalOperator.Equal,
          value: [2]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        // otherFieldId: "Partner_EmploymentData-EmployedUntilMonth",
        otherFieldId: totJaar2MonthPartnerFieldId,
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-EmployedUntilYear",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Tot jaar",
      labelHint:
        "Vul de maand en het jaar in van de einddatum van uw uitkering.",
      inputType: InputType.SELECT,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 50,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      fieldColumnsWidth: 3,
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Partner_EmploymentData-EmployedUntilMonth",
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment("2100-01").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-Employer",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Bedrijfsnaam",
      labelHint: "Vul a.u.b. de naam in van uw werkgever.",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationRegex: /[^a-zA-Z\s]+$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-Job",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Beroep",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 25,
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      // validationRegex: "^[0-9a-zA-Z]{1,25}$",
      validationRegex: /[^a-zA-Z\s]+$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-NetIncome",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Netto inkomen",
      labelHint:
        "Vul uw terugkerend nettoinkomen in dus dat wat u met regelmaat krijgt uitbetaald.",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minLength: 1,
      maxLength: 10,
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-IncomePeriod",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Uitbetaling per",
      labelHint:
        "Geef a.u.b. aan in welke regelmaat uw uitkering ontvangen wordt.",
      inputType: InputType.SELECT,
      options: getIncomePeriodList(),
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-EmploymentType",
          operator: ConditionalOperator.Equal,
          value: [1, 2, 4, 8, 10]
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-ExtraIncome",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Extra of ander inkomen",
      labelHint:
        "Heeft u naast uw hoofdinkomen nog andere regelmatig terugkerende inkomsten? Dan kunt u deze hier aangeven.",
      inputType: InputType.SELECT_CUSTOM,
      isMultiSelect: true,
      options: await getOthersNetIncomeList(),
      groupingArea: {
        title: "Extra inkomen toevoegen"
      },
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-HasExtraIncome",
          operator: ConditionalOperator.Equal,
          value: "1"
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-TotalAmountExtraIncome",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Totaal bedrag aan extra inkomen",
      labelHint:
        "Vul het netto totaalbedrag (opgeteld) van uw extra inkomsten in",
      inputType: InputType.NUMBER,
      groupingArea: {
        title: "Extra inkomen toevoegen"
      },
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-HasExtraIncome",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-PaymentPer",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Uitbetaling per",
      labelHint:
        "Geef m.b.v. onderstaande keuzes aan in welke frequentie u betaald krijgt.",
      inputType: InputType.SELECT,
      options: getIncomePeriodList(),
      groupingArea: {
        title: "Extra inkomen toevoegen"
      },
      conditional: [
        {
          dependsOn: "Partner_EmploymentData-HasExtraIncome",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_EmploymentData-HasExtraIncome",
      belongsToStep: getB2CSubSteps()[5].groupTitle,
      labelText: "Voeg extra inkomen toe",
      toggleText: "Annuleer",
      inputType: InputType.TOGGLE_ADD_FIELD_BUTTON,
      fieldColumnsWidth: 12,
      value: 0
      // conditional: {
      //   dependsOn: "Partner_EmploymentData-HasExtraIncome",
      //   operator: ConditionalOperator.Equal,
      //   value: "0"
      // },
      // visualRules: {
      //   hardInvisibility: true
      // }
    },

    // Form Step 3 - substep 1

    // {
    //   internalId: uuidv4(),
    //   fieldId: "Main_FinancialData-custom-hint1",
    //   belongsToStep: getB2CSubSteps()[6].groupTitle,
    //   labelText: "Test",
    //   inputType: InputType.CUSTOM_HINT
    // },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-TypeOfHouse",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Soort woning",
      inputType: InputType.SELECT,
      options: getTypeOfHouseList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-GrossHousingCosts",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Bruto woonlasten per maand",
      labelHint: "Vul het bruto maandbedrag voor uw woonlasten in.",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minValue: 0,
      minLength: 1,
      maxLength: 10,
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-HasChildSupport",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Betaalt u kinderalimentatie?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-ChildSupportPerMonth",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Vul het totale bedrag aan kinderalimentatie per maand in",
      inputType: InputType.NUMBER,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasChildSupport",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      minValue: 1,
      minLength: 1,
      maxLength: 10,
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-HasPartnerSupport",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Betaalt u partneralimentatie?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-PartnerSupportPerMonth",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Vul het totale bedrag aan partneralimentatie per maand in",
      inputType: InputType.NUMBER,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasPartnerSupport",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      minValue: 1,
      minLength: 1,
      maxLength: 10,
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-HasDaycareCosts",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Betaalt u kosten voor geregistreerde kinderopvang?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-DaycareCostsPerMonth",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Kosten kinderopvang per maand",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minLength: 1,
      maxLength: 10,
      minValue: 1,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasDaycareCosts",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-ChildSupportPerMonthReceived",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Hoeveel kinderopvangtoeslag per maand ontvangt u?",
      inputType: InputType.NUMBER,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasDaycareCosts",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true,
        extraClassNames: "ms-auto"
      },
      minValue: 1,
      minLength: 1,
      maxLength: 10,
      validationRegex: "^[0-9a-zA-Z]{1,30}$",
      validationMessage: "Dit is een verplicht veld!"
    },

    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-LoanAmount",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Maandelijks terugbetaalregeling(en)",
      labelHint:
        "Alleen invullen indien de regeling nog meer dan 6 maanden doorloopt.",
      groupingArea: {
        title: "Overige kosten"
      },
      value: "0",
      minValue: 0,
      minLength: 1,
      maxLength: 10,
      inputType: InputType.NUMBER,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-HasStudentLoan" + uuidv4(),
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      inputType: InputType.EMPTY_BLOCK,
      labelText: "",
      groupingArea: {
        title: "Overige kosten"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-HasStudentLoan",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Studielening (DUO)",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-IsStudentLoanPayed",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Wordt deze lening reeds afbetaald?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-OutstandingStudentLoan",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Wat is de openstaande studieschuld?",
      inputType: InputType.NUMBER,
      minValue: 1,
      minLength: 1,
      maxLength: 20,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        },
        {
          dependsOn: "Main_FinancialData-IsStudentLoanPayed",
          operator: ConditionalOperator.Equal,
          value: 2
        }
      ],
      validationRegex: "^[0-9a-zA-Z]{1,20}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-StudentLoanBefore",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Is deze studieschuld ontstaan voor 1-7-2015?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        },
        {
          dependsOn: "Main_FinancialData-IsStudentLoanPayed",
          operator: ConditionalOperator.Equal,
          value: 2
        }
      ],
      validationMessage: "Dit is een verplicht veld!",
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Main_FinancialData-StudentLoanCosts",
      belongsToStep: getB2CSubSteps()[6].groupTitle,
      labelText: "Maandelijkse DUO lasten",
      labelHint:
        "Vul het bedrag in dat u maandelijks betaalt om uw studieschuld af te lossen.",
      inputType: InputType.NUMBER,
      minValue: 1,
      minLength: 1,
      maxLength: 20,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Main_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        },
        {
          dependsOn: "Main_FinancialData-IsStudentLoanPayed",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      // validationRegex: "^[0-9a-zA-Z]{1,20}$",
      validationMessage: "Dit is een verplicht veld!"
    },

    // Form Step 3 - substep 2
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-HasChildSupport",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Betaalt u kinderalimentatie?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-ChildSupportPerMonth",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Vul het totale bedrag aan kinderalimentatie per maand in",
      inputType: InputType.NUMBER,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasChildSupport",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      minValue: 0,
      minLength: 1,
      maxLength: 10,
      validationRegex: "^[0-9a-zA-Z]{1,30}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-HasPartnerSupport",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Betaalt u partneralimentatie?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-PartnerSupportPerMonth",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Vul het totale bedrag aan kinderalimentatie per maand in",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minValue: 1,
      minLength: 1,
      maxLength: 10,
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasPartnerSupport",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-HasDaycareCosts",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Betaalt u kosten voor geregistreerde kinderopvang?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-DaycareCostsPerMonth",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Kosten kinderopvang per maand",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minValue: 1,
      minLength: 1,
      maxLength: 10,
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasDaycareCosts",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      validationRegex: "^[0-9]{1,10}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-ChildSupportPerMonthReceived",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Hoeveel kinderopvangtoeslag per maand ontvangt u?",
      inputType: InputType.NUMBER,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasDaycareCosts",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: true,
        extraClassNames: "ms-auto"
      },
      minValue: 1,
      minLength: 1,
      maxLength: 10,
      validationRegex: "^[0-9a-zA-Z]{1,30}$",
      validationMessage: "Dit is een verplicht veld!"
    },

    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-LoanAmount",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Maandelijks terugbetaalregeling(en)",
      labelHint:
        "Alleen invullen indien de regeling nog meer dan 6 maanden doorloopt.",
      groupingArea: {
        title: "Overige kosten"
      },
      inputType: InputType.NUMBER,
      value: "0",
      minValue: 0,
      minLength: 1,
      maxLength: 10,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-HasStudentLoan" + uuidv4(),
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      inputType: InputType.EMPTY_BLOCK,
      labelText: "",
      groupingArea: {
        title: "Overige kosten"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-HasStudentLoan",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Studielening (DUO)",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-IsStudentLoanPayed",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Wordt deze lening reeds afbetaald?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-OutstandingStudentLoan",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Wat is de openstaande studieschuld?",
      inputType: InputType.NUMBER,
      minValue: 1,
      minLength: 1,
      maxLength: 20,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        },
        {
          dependsOn: "Partner_FinancialData-IsStudentLoanPayed",
          operator: ConditionalOperator.Equal,
          value: 2
        }
      ],
      validationRegex: "^[0-9a-zA-Z]{1,20}$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-StudentLoanBefore",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Is deze studieschuld ontstaan voor 1-7-2015?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 2,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        },
        {
          dependsOn: "Partner_FinancialData-IsStudentLoanPayed",
          operator: ConditionalOperator.Equal,
          value: 2
        }
      ],
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Partner_FinancialData-StudentLoanCosts",
      belongsToStep: getB2CSubSteps()[7].groupTitle,
      labelText: "Maandelijkse DUO lasten",
      labelHint:
        "Vul het bedrag in dat u maandelijks betaalt om uw studieschuld af te lossen.",
      inputType: InputType.NUMBER,
      minValue: 1,
      minLength: 1,
      maxLength: 20,
      isMandatory: true,
      conditional: [
        {
          dependsOn: "Partner_FinancialData-HasStudentLoan",
          operator: ConditionalOperator.Equal,
          value: 1
        },
        {
          dependsOn: "Partner_FinancialData-IsStudentLoanPayed",
          operator: ConditionalOperator.Equal,
          value: 1
        }
      ],
      // validationRegex: "^[0-9a-zA-Z]{1,20}$",
      validationMessage: "Dit is een verplicht veld!"
    }
  ];

  // console.log("@", a);
  // return Promise.resolve(a);
  return a;
};

export const getAllB2BFormFields = async (): Promise<TFormField[]> => {
  return [
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-CompanyName",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Bedrijfsnaam formele naam",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      validationRegex: /[^a-zA-Z0-9\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-PostCode",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Postcode",
      // labelHint: "Controleer postcode/huisnummer.",
      inputType: InputType.API_POSTCODE,
      isMandatory: true,
      minLength: 6,
      maxLength: 6,
      validationNoWhiteSpaces: true,
      validationRegex: /^[1-9]{1}[0-9]{3} ?(?!sa|sd|ss)[a-zA-Z]{2}$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-HouseNumber",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Huisnummer",
      // labelHint: "Controleer postcode/huisnummer.",
      inputType: InputType.API_HOUSENUMBER,
      minLength: 1,
      isMandatory: true,
      maxLength: 6,
      validationRegex: "^[0-9]{1,6}$",
      validationNoWhiteSpaces: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-Additional",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Toevoeging",
      inputType: InputType.TEXT,
      maxLength: 10,
      validationRegex: /[^a-zA-Z0-9]+$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-Street",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Straat",
      inputType: InputType.TEXT,
      isMandatory: true,
      isDisabled: true,
      maxLength: 50,
      filledBy: [
        "BusinessInformation-PostCode",
        "BusinessInformation-HouseNumber"
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-City",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Plaats",
      inputType: InputType.TEXT,
      isMandatory: true,
      isDisabled: true,
      maxLength: 50,
      filledBy: [
        "BusinessInformation-PostCode",
        "BusinessInformation-HouseNumber"
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-ChamberOfCommerceNumber",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Kamer van koophandelnummer KVK",
      labelHint: "Controleer of KvK-nummer 8 cijfers is.",
      inputType: InputType.NUMBER,
      isMandatory: true,
      minLength: 8,
      maxLength: 8,
      validationRegex: /^[0-9]+$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-DateFounded",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Datum van inschrijving ",
      inputType: InputType.DATE,
      isMandatory: true,
      maxValue: +moment().format("x"), //(today is the max date),
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-LegalEntity",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Rechtsvorm",
      inputType: InputType.SELECT,
      options: getLegalEntityList(),
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-VATNumber",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "BTW-Nummer",
      labelHint: "BTW-nummer is land code + 9 cijfers.",
      // inputType: InputType.TEXT, // @TODO: Check if is number or text
      inputPlaceholder: "NL_________B__",
      inputType: InputType.CUSTOM_MASK,
      isMandatory: true,
      minLength: 14,
      maxLength: 14,
      // validationRegex: /^NL[0-9]{9}[B][0-9]{2}$/gi, //@TODO: Fix this
      validationRegex: /^NL[0-9]{9}[B][0-9]{2}$/, //@TODO: Fix this
      visualRules: {
        hardInvisibility: false,
        // mask: ""
        mask: /^NL[0-9]{9}[B][0-9]{2}$/gim
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-IBAN",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "IBAN",
      inputType: InputType.IBAN,
      isMandatory: true,
      maxLength: 50,
      // validationRegex: /^NL[0-9]{2}[A-Za-z0-9]{4}[0-9]{10}$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "BusinessInformation-Website",
      belongsToStep: getB2BSubSteps()[0].groupTitle,
      labelText: "Website",
      inputType: InputType.URL,
      // isMandatory: true,
      maxLength: 200,
      // validationRegex:
      //   /^(http:\/\/|https:\/\/)?(www\.)?[a-zA-Z0-9-_.]+\.[a-zA-Z]+(:\d+)?(\/[a-zA-Z\d.\-_]*)*[a-zA-Z.!@#$%&=-_'":,.?\d*)(]*$/gim,
      // validationRegex:
      //   /^(?:www\.)?([-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b)*(\/[/\d\w.-]*)*(?:[?])*(.+)*/gim,
      validationMessage: "Dit is een verplicht veld!"
    },

    //Step 2 - Contact persoon

    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-FirstName",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Voornaam",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-LastName",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Achternaam",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-Prefix",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Tussenvoegsel (optioneel)",
      inputType: InputType.TEXT,
      maxLength: 10,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-EmailAddress",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "E-mailadres",
      inputType: InputType.EMAIL,
      isMandatory: true,
      maxLength: 50,
      // validationRegex: new RegExp(
      //   /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      //   "gi"
      // ),
      validationRegex: "^.+@.+.[A-Za-z]+$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-WorkTelephoneNumber",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Werk Telefoonnummer",
      labelHint: "Minimaal 10 cijfers.",
      value: "+31",
      minLength: 12, // It's 13 plus 2 spaces resulting 15 in total
      maxLength: 15,
      inputType: InputType.TEL,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-MobilePhoneNumber",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Mobiele Telefoonnummer",
      labelHint: "Minimaal 10 cijfers.",
      minLength: 12, // It's 13 plus 2 spaces resulting 15 in total
      maxLength: 15,
      inputType: InputType.TEL,
      isMandatory: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-CustomTitle1",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Bestuurder",
      inputType: InputType.CUSTOM_TITLE,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "h3"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverSameAsContactPerson",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Is de contactpersoon ook de bestuurder?",
      inputType: InputType.RADIO,
      options: yesOrNoList(),
      value: 1,
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverPersonFirstName",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Voornaam",
      inputType: InputType.TEXT,
      isMandatory: true,
      minLength: 1,
      maxLength: 50,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!",
      visualRules: {
        hardInvisibility: true
      },
      conditional: [
        {
          dependsOn: "ContactPerson-DriverSameAsContactPerson",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ]
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverPersonPrefix",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Tussenvoegsel (optioneel)",
      inputType: InputType.TEXT,
      maxLength: 10,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!",
      visualRules: {
        hardInvisibility: true
      },
      conditional: [
        {
          dependsOn: "ContactPerson-DriverSameAsContactPerson",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ]
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverPersonLastName",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Achternaam",
      inputType: InputType.TEXT,
      minLength: 1,
      maxLength: 50,
      isMandatory: true,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationNoNumbers: true,
      validationMessage: "Dit is een verplicht veld!",
      visualRules: {
        hardInvisibility: true
      },
      conditional: [
        {
          dependsOn: "ContactPerson-DriverSameAsContactPerson",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ]
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverPersonEmailAddress",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "E-mailadres",
      inputType: InputType.EMAIL,
      maxLength: 50,
      isMandatory: true,
      visualRules: {
        hardInvisibility: true
      },
      validationMessage: "Dit is een verplicht veld!",
      validationRegex: "^.+@.+.[A-Za-z]+$",
      conditional: [
        {
          dependsOn: "ContactPerson-DriverSameAsContactPerson",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ]
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverPersonWorkTelNumber",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Werk Telefoonnummer",
      inputType: InputType.TEL,
      minLength: 12, // It's 13 plus 2 spaces resulting 15 in total
      maxLength: 15,
      isMandatory: true,
      visualRules: {
        hardInvisibility: true
      },
      conditional: [
        {
          dependsOn: "ContactPerson-DriverSameAsContactPerson",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "ContactPerson-DriverPersonWorkMobNumber",
      belongsToStep: getB2BSubSteps()[1].groupTitle,
      labelText: "Mobiele Telefoonnummer",
      inputType: InputType.TEL,
      minLength: 12, // It's 13 plus 2 spaces resulting 15 in total
      maxLength: 15,
      isMandatory: true,
      visualRules: {
        hardInvisibility: true
      },
      conditional: [
        {
          dependsOn: "ContactPerson-DriverSameAsContactPerson",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },

    // Step 3 -Directors

    {
      internalId: uuidv4(),
      fieldId: "Directors-NumberOfDirectors",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Aantal directors",
      inputType: InputType.SELECT,
      value: 1,
      options: [
        {
          label: "1",
          value: 1
        },
        {
          label: "2",
          value: 2
        }
        // {
        //   label: "3",
        //   value: 3
        // }
      ]
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-DirectorSameAsContactPerson",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Is de contact persoon ook de director?",
      labelHint:
        "Indien ja, dan moet dit zichtbaar zijn in de KvK-registratie.",
      inputType: InputType.RADIO,
      value: 2,
      options: yesOrNoList(),
      visualRules: {
        hardInvisibility: false,
        extraClassNames: "flex-wrap"
      },
      callbackFunction: {
        triggerConditionValue: 1,
        name: "copyDirector1FieldsFromOtherStep",
        // args: ["1", "z"]
        args: undefined
      }
    },

    // Director 1
    {
      internalId: uuidv4(),
      fieldId: "Directors-FirstName",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Voorletters",
      inputType: InputType.TEXT,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      minLength: 1,
      maxLength: 5,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-LastName",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Achternaam",
      labelHint:
        "Een dubbele achternaam? Verbind uw achternamen dan met een streepje, bijvoorbeeld 'Groot-Bruggink'. Gehuwd? Vul dan alleen uw geboortenaam in.",
      inputType: InputType.TEXT,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      minLength: 1,
      maxLength: 50,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Prefix",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Tussenvoegsel (optioneel)",
      inputType: InputType.TEXT,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      maxLength: 10,
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-DateOfBirth",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Geboortedatum",
      inputType: InputType.DATE,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      minValue: +moment().subtract(100, "year").format("x"), //(today-filledDate = 100 years)
      maxValue: +moment().subtract(18, "year").format("x"), //(today-filledDate = 18 years),
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Nationality",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Nationaliteit",
      inputType: InputType.SELECT,
      // value: 1,
      options: await getCountryList(),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationRegex: "^[1-9]d*$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-DateInFunction",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Datum in functie",
      inputType: InputType.DATE,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      maxValue: +moment().format("x"), //(today-filledDate = 18 years)
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Authorization",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Tekenbevoegdheid",
      inputType: InputType.SELECT,
      options: [
        //entity: SigningAuthority
        {
          label: "Onbeperkt",
          value: 1
        },
        {
          label: "Beperkt",
          value: 2
        }
      ],
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For nationals
      internalId: uuidv4(),
      fieldId: "Directors-IDType",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => _.value !== 2),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Directors-Nationality",
          value: 1,
          operator: ConditionalOperator.Equal
        }
      ],
      visualRules: {
        hardInvisibility: true
      }
    },
    {
      //For EU
      internalId: uuidv4(),
      fieldId: "Directors-IDType",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => ![1, 2].includes(+_.value)),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Directors-Nationality",
          value: 1,
          operator: ConditionalOperator.Different
        },
        {
          dependsOn: "Directors-Nationality",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.InArray
        }
      ],
      visualRules: {
        hardInvisibility: true
      }
    },
    {
      //For non EU
      internalId: uuidv4(),
      fieldId: "Directors-IDType",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => [2].includes(+_.value)),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      value: 2,
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Directors-Nationality",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.NotInArray
        }
      ],
      visualRules: {
        hardInvisibility: true
      }
    },

    {
      internalId: uuidv4(),
      fieldId: "Directors-ResidencePermitType",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Soort Verblijfsvergunning",
      inputType: InputType.SELECT,
      options: getTypeOfResidencePermit(),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationMessage: "Dit is een verplicht veld!",
      conditional: [
        {
          dependsOn: "Directors-IDType",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ]
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-IDNumber",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Documentnummer",
      inputType: InputType.TEXT,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      minLength: 1,
      maxLength: 30,
      validationRegex: /^[a-zA-Z0-9]+$/gim,
      validationNoWhiteSpaces: true,
      validationNoSpecialChar: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-ValidUntilMonth",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Geldig tot maand",
      inputType: InputType.SELECT,
      options: getMonthList(),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Directors-ValidUntilYear",
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-ValidUntilYear",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Geldig tot jaar",
      inputType: InputType.SELECT,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 10,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Directors-ValidUntilMonth",
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Iterator",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "",
      inputType: InputType.HIDDEN,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 1"
      },
      value: 1
    },

    // Director 2
    {
      internalId: uuidv4(),
      fieldId: "Directors-FirstName2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Voorletters",
      inputType: InputType.TEXT,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      minLength: 1,
      maxLength: 5,
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-LastName2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Achternaam",
      labelHint:
        "Een dubbele achternaam? Verbind uw achternamen dan met een streepje, bijvoorbeeld 'Groot-Bruggink'. Gehuwd? Vul dan alleen uw geboortenaam in.",
      inputType: InputType.TEXT,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      minLength: 1,
      maxLength: 50,
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Prefix2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Tussenvoegsel (optioneel)",
      inputType: InputType.TEXT,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      maxLength: 10,
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationRegex: /[^a-zA-Z\s]$/gim,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-DateOfBirth2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Geboortedatum",
      inputType: InputType.DATE,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      minValue: +moment().subtract(100, "year").format("x"), //(today-filledDate = 100 years)
      maxValue: +moment().subtract(18, "year").format("x"), //(today-filledDate = 18 years),
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Nationality2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Nationaliteit",
      inputType: InputType.SELECT,
      // value: 1,
      options: await getCountryList(),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationRegex: "^[1-9]d*$",
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-DateInFunction2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Datum in functie",
      inputType: InputType.DATE,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      maxValue: +moment().format("x"), //(today-filledDate = 18 years)
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Authorization2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Tekenbevoegdheid",
      inputType: InputType.SELECT,
      options: [
        //entity: SigningAuthority
        {
          label: "Onbeperkt",
          value: 1
        },
        {
          label: "Beperkt",
          value: 2
        }
      ],
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      //For nationals
      internalId: uuidv4(),
      fieldId: "Directors-IDType2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => _.value !== 2),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        },
        {
          dependsOn: "Directors-Nationality2",
          value: 1,
          operator: ConditionalOperator.Equal
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      // For EU non nederlaners
      internalId: uuidv4(),
      fieldId: "Directors-IDType2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => ![1, 2].includes(+_.value)),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        },
        {
          dependsOn: "Directors-Nationality2",
          value: 1,
          operator: ConditionalOperator.Different
        },
        {
          dependsOn: "Directors-Nationality2",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.InArray
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      // For non EU
      internalId: uuidv4(),
      fieldId: "Directors-IDType2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Legitimatiebewijs",
      inputType: InputType.SELECT,
      options: getIdTypeList().filter((_) => [2].includes(+_.value)),
      isMandatory: true,
      // value: 2,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        },
        {
          dependsOn: "Directors-Nationality2",
          value: await getCountryList().then((r) =>
            r
              .filter(
                (country: IFieldCountriesDataOptions) => country.IsEU === true
              )
              .map((country: IFieldCountriesDataOptions) => {
                return country.value.toString();
              })
          ),
          operator: ConditionalOperator.NotInArray
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-ResidencePermitType2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Soort Verblijfsvergunning",
      inputType: InputType.SELECT,
      options: getTypeOfResidencePermit(),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        },
        {
          dependsOn: "Directors-IDType2",
          value: 2,
          operator: ConditionalOperator.Equal
        }
      ],
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-IDNumber2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Documentnummer",
      inputType: InputType.TEXT,
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      minLength: 1,
      maxLength: 30,
      validationRegex: /^[a-zA-Z0-9]+$/gim,
      validationNoWhiteSpaces: true,
      validationNoSpecialChar: true,
      validationMessage: "Dit is een verplicht veld!"
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-ValidUntilMonth2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Geldig tot maand",
      inputType: InputType.SELECT,
      options: getMonthList(),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Directors-ValidUntilYear2",
        isMonth: true,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-ValidUntilYear2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "Geldig tot jaar",
      inputType: InputType.SELECT,
      options: range(
        new Date().getFullYear(),
        new Date().getFullYear() + 10,
        +1
      ).map((i) => {
        return {
          label: i.toString(),
          value: i
        };
      }),
      isMandatory: true,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      validationMessage: "Dit is een verplicht veld!",
      customDateValidation: {
        otherFieldId: "Directors-ValidUntilMonth2",
        isMonth: false,
        combinationMinValue: +moment().unix(),
        combinationMaxValue: +moment().add(10, "year").unix()
      }
    },
    {
      internalId: uuidv4(),
      fieldId: "Directors-Iterator2",
      belongsToStep: getB2BSubSteps()[2].groupTitle,
      labelText: "",
      inputType: InputType.HIDDEN,
      groupingArea: {
        title: "Directeur/Eigenaar/Tekenbevoegde 2"
      },
      conditional: [
        {
          dependsOn: "Directors-NumberOfDirectors",
          value: 2,
          operator: ConditionalOperator.GreatherOrEqualThan
        }
      ],
      value: 2
    }

    // Director 3
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-FirstName3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Voorletters",
    //   inputType: InputType.TEXT,
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   minLength: 1,
    //   maxLength: 5,
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationRegex: /[^a-zA-Z\s]$/gim,
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-LastName3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Achternaam",
    //   labelHint:
    //     "Een dubbele achternaam? Verbind uw achternamen dan met een streepje, bijvoorbeeld 'Groot-Bruggink'. Gehuwd? Vul dan alleen uw geboortenaam in.",
    //   inputType: InputType.TEXT,
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   minLength: 1,
    //   maxLength: 50,
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationRegex: /[^a-zA-Z\s]$/gim,
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-Prefix3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Tussenvoegsel (optioneel)",
    //   inputType: InputType.TEXT,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   maxLength: 10,
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationRegex: /[^a-zA-Z\s]$/gim,
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-DateOfBirth3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Geboortedatum",
    //   inputType: InputType.DATE,
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   minValue: +moment().subtract(100, "year").format("x"), //(today-filledDate = 100 years)
    //   maxValue: +moment().subtract(18, "year").format("x"), //(today-filledDate = 18 years),
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-Nationality3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Nationaliteit",
    //   inputType: InputType.SELECT,
    //   value: 1,
    //   options: await getCountryList(),
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationRegex: "^[1-9]d*$",
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-DateInFunction3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Datum in functie",
    //   inputType: InputType.DATE,
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   maxValue: +moment().format("x"), //(today-filledDate = 18 years)
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-Authorization3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Tekenbevoegdheid",
    //   inputType: InputType.SELECT,
    //   options: [
    //     //entity: SigningAuthority
    //     {
    //       label: "Onbeperkt",
    //       value: 1
    //     },
    //     {
    //       label: "Beperkt",
    //       value: 2
    //     }
    //   ],
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   // For netherlanders
    //   internalId: uuidv4(),
    //   fieldId: "Directors-IDType3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Legitimatiebewijs",
    //   inputType: InputType.SELECT,
    //   options: getIdTypeList().filter((_) => _.value !== 2),
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: [
    //     {
    //       dependsOn: "Directors-NumberOfDirectors",
    //       value: 3,
    //       operator: ConditionalOperator.GreatherOrEqualThan
    //     },
    //     {
    //       dependsOn: "Directors-Nationality3",
    //       value: 1,
    //       operator: ConditionalOperator.Equal
    //     }
    //   ],
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   // for EU non netherlanders
    //   internalId: uuidv4(),
    //   fieldId: "Directors-IDType3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Legitimatiebewijs",
    //   inputType: InputType.SELECT,
    //   options: getIdTypeList().filter((_) => ![1, 2].includes(+_.value)),
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: [
    //     {
    //       dependsOn: "Directors-NumberOfDirectors",
    //       value: 3,
    //       operator: ConditionalOperator.GreatherOrEqualThan
    //     },
    //     {
    //       dependsOn: "Directors-Nationality3",
    //       value: 1,
    //       operator: ConditionalOperator.Different
    //     },
    //     {
    //       dependsOn: "Directors-Nationality3",
    //       value: await getCountryList().then((r) =>
    //         r
    //           .filter(
    //             (country: IFieldCountriesDataOptions) => country.IsEU === true
    //           )
    //           .map((country: IFieldCountriesDataOptions) => {
    //             return country.value.toString();
    //           })
    //       ),
    //       operator: ConditionalOperator.InArray
    //     }
    //   ],
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   // for non EU
    //   internalId: uuidv4(),
    //   fieldId: "Directors-IDType3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Legitimatiebewijs",
    //   inputType: InputType.SELECT,
    //   options: getIdTypeList().filter((_) => [2].includes(+_.value)),
    //   isMandatory: true,
    //   value: 2,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: [
    //     {
    //       dependsOn: "Directors-NumberOfDirectors",
    //       value: 3,
    //       operator: ConditionalOperator.GreatherOrEqualThan
    //     },
    //     {
    //       dependsOn: "Directors-Nationality3",
    //       value: await getCountryList().then((r) =>
    //         r
    //           .filter(
    //             (country: IFieldCountriesDataOptions) => country.IsEU === true
    //           )
    //           .map((country: IFieldCountriesDataOptions) => {
    //             return country.value.toString();
    //           })
    //       ),
    //       operator: ConditionalOperator.NotInArray
    //     }
    //   ],
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-ResidencePermitType3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Soort Verblijfsvergunning",
    //   inputType: InputType.SELECT,
    //   options: getTypeOfResidencePermit(),
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: [
    //     {
    //       dependsOn: "Directors-NumberOfDirectors",
    //       value: 3,
    //       operator: ConditionalOperator.GreatherOrEqualThan
    //     },
    //     {
    //       dependsOn: "Directors-IDType3",
    //       value: 2,
    //       operator: ConditionalOperator.Equal
    //     }
    //   ],
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-IDNumber3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Documentnummer",
    //   inputType: InputType.TEXT,
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   minLength: 1,
    //   maxLength: 30,
    //   validationRegex: /^[a-zA-Z0-9]+$/gim,
    //   validationNoWhiteSpaces: true,
    //   validationNoSpecialChar: true,
    //   validationMessage: "Dit is een verplicht veld!"
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-ValidUntilMonth3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Geldig tot maand",
    //   inputType: InputType.SELECT,
    //   options: getMonthList(),
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationMessage: "Dit is een verplicht veld!",
    //   customDateValidation: {
    //     otherFieldId: "Directors-ValidUntilYear3",
    //     isMonth: true,
    //     combinationMinValue: +moment().unix(),
    //     combinationMaxValue: +moment().add(10, "year").unix()
    //   }
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-ValidUntilYear3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "Geldig tot jaar",
    //   inputType: InputType.SELECT,
    //   options: range(
    //     new Date().getFullYear(),
    //     new Date().getFullYear() + 10,
    //     +1
    //   ).map((i) => {
    //     return {
    //       label: i.toString(),
    //       value: i
    //     };
    //   }),
    //   isMandatory: true,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   validationMessage: "Dit is een verplicht veld!",
    //   customDateValidation: {
    //     otherFieldId: "Directors-ValidUntilMonth2",
    //     isMonth: false,
    //     combinationMinValue: +moment().unix(),
    //     combinationMaxValue: +moment().add(10, "year").unix()
    //   }
    // },
    // {
    //   internalId: uuidv4(),
    //   fieldId: "Directors-Iterator3",
    //   belongsToStep: getB2BSubSteps()[2].groupTitle,
    //   labelText: "",
    //   inputType: InputType.HIDDEN,
    //   groupingArea: {
    //     title: "Directeur/Eigenaar/Tekenbevoegde 3"
    //   },
    //   conditional: {
    //     dependsOn: "Directors-NumberOfDirectors",
    //     value: 3,
    //     operator: ConditionalOperator.GreatherOrEqualThan
    //   },
    //   value: 3
    // }
  ];
};
