import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./AppForm.css";
import { FormControl, IFormControl } from "./FormControl";
import { TFormField, TSubStep } from "./WizardCmp";
import Stepper from "./Stepper";
import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { WizardContext } from "../Contexts/WizardContext";
import {
  IFieldDataCondition,
  IFieldDataConfig
} from "../Types/FieldDataConfig";
import {
  americanDateFormat,
  checkDateDependency2FieldsMatch,
  checkDateDependencyMatch,
  checkDependencyMatch,
  checkFieldIsValid,
  checkFieldValidation,
  findElAndIndexInDeepArray as getElAndIndexInDeepArrayByAttr,
  validate2DatesFields
} from "../helpers";
import { InputType, addressTypes } from "../Types/InputType";
import { getAddressApiCall, validateIBANApiCall } from "../Store/ApiCalls";
import { monthList } from "../Store/MonthList";
import { valueToNode } from "@babel/types";

export interface IAppForm {
  formType: string;
  currentSubStep: TSubStep;
  onMoveStep: (stepMove: number, isFinished?: boolean) => void;
}

function AppForm(props: { data: IAppForm }) {
  const wizardContext = useContext(WizardContext);

  // const [fieldList, setFieldList] = useState<TFormField[]>(
  //   wizardContext.formFields
  //     .filter((f) => f.belongsToStep === props.data.currentSubStep.groupTitle)
  //     .map((f: TFormField) => {
  //       return {
  //         ...f
  //       };
  //     })
  // );
  const [fieldList, setFieldList] = useState<TFormField[]>([]);
  const [lastInputChanged, setLastInputChanged] = useState<TFormField>();
  const [randomNo, setRandomNo] = useState(Math.random());

  const inputRefList = useRef<any>([]);

  const onClickPrev = () => {
    props.data.onMoveStep(-1);
  };
  const onClickNext = () => {
    // console.log(fieldList);
    wizardContext.save(props.data.currentSubStep.apiName, fieldList);
    // props.data.onMoveStep(+1, isFinished);
    props.data.onMoveStep(+1);
  };

  useEffect(() => {
    // console.log(wizardContext.formFields);
    // console.log("#here");
    if (wizardContext.formFields.length > 0) {
      const fieldListFiltered = wizardContext.formFields
        .filter((f) => f.belongsToStep === props.data.currentSubStep.groupTitle)
        .map((f) => {
          f.isFieldVisible = fieldHasConditionalVisibilityAndItIsMatchCB(
            f,
            wizardContext.formFields
          );
          // console.log(f.labelText, f.isFieldVisible);
          return {
            ...f
          };
        });
      // console.log(fieldListFiltered);
      // console.log(fieldListFiltered.length, props.data.currentSubStep);
      inputRefList.current = Array(fieldListFiltered.length)
        .fill("")
        .map((_, i) => {
          return inputRefList.current[i] || createRef();
        });
      // console.log(inputRefList);
      // console.log(fieldListFiltered);
      setFieldList(fieldListFiltered);
    }
  }, [wizardContext.formFields, props.data.currentSubStep]);

  function getDependantFields(editedFieldId: string) {
    return fieldList.filter(
      (_) => _.filledBy !== undefined && _.filledBy.includes(editedFieldId)
    );
  }

  function getFieldById(id: string) {
    return fieldList.filter(
      (el) =>
        el.fieldId.toLowerCase() === id.toLowerCase() &&
        el.isFieldVisible === true
    )[0];
  }

  const checkAndSetAddressFields = useCallback(() => {
    // 1- Get fields that depends on the value of the other fields;
    // console.log("input that changed", lastInputChanged);
    const dependantFields = getDependantFields(lastInputChanged!.fieldId);
    // console.log(dependantFields);

    // 2- Create an ID (of fields) list that is required by other fields;
    const fieldIdListTriggers: {
      fieldId: string;
      currentVal: string;
    }[] = [];
    dependantFields.map((_: any) => {
      _.filledBy?.map((id: string) => {
        const fieldId = id.toLowerCase();
        const elExists = fieldIdListTriggers.find(
          (el) => el.fieldId === fieldId
        );
        if (elExists === undefined) {
          fieldIdListTriggers.push({
            fieldId: fieldId,
            currentVal: getFieldById(fieldId).value
              ? getFieldById(fieldId).value! + ""
              : ""
          });
        }
      });
    });

    // 3- Check the value of the fields in (2) to match rules and fill the dependant fields
    // console.log("dependantFields", dependantFields);
    // console.log("fieldIdListTriggers", fieldIdListTriggers);
    dependantFields.map((_, index) => {
      // let isAllDependenciesFilled = false;
      // const isAllDependenciesFilled: boolean[] = [];
      const allDependenciesFilledFieldIds: any[] = [];
      _.filledBy?.map((referenceFieldId) => {
        const referencedFieldData = getFieldById(referenceFieldId);
        // console.log("referencedFieldData", referencedFieldData);
        if (referencedFieldData.value !== undefined) {
          // isAllDependenciesFilled = true;
          // console.log(
          //   "#",
          //   referencedFieldData,
          //   referencedFieldData.value.toString(),
          //   referencedFieldData.value.toString().length
          // );
          if (
            referencedFieldData.minLength &&
            referencedFieldData.minLength <=
              referencedFieldData.value.toString().length
          ) {
            // console.log("aa");
            // isAllDependenciesFilled.push(true);
            allDependenciesFilledFieldIds.push(referencedFieldData.internalId);
          }
        } /*else {
          isAllDependenciesFilled = false;
        }*/
      });
      // console.log(
      //   "allDependenciesFilledFieldIds",
      //   allDependenciesFilledFieldIds
      // );
      // console.log(dependantFields);
      //@TODO: Check this
      if (allDependenciesFilledFieldIds.length === 2 && index % 2) {
        //Do fetch address from API
        getAddressApiCall(
          getElAndIndexInDeepArrayByAttr(
            fieldList,
            "internalId",
            allDependenciesFilledFieldIds[0]
          ).element.value!,
          getElAndIndexInDeepArrayByAttr(
            fieldList,
            "internalId",
            allDependenciesFilledFieldIds[1]
          ).element.value!
        )
          .then((r) => {
            // console.log("success", r);
            updateFieldAttr(dependantFields[0].internalId, "value", r.street);
            updateFieldAttr(dependantFields[1].internalId, "value", r.city);
            updateFieldAttr(allDependenciesFilledFieldIds[0], "isValid", true);
            updateFieldAttr(allDependenciesFilledFieldIds[1], "isValid", true);
          })
          .catch((err) => {
            // console.log("err", err);
            updateFieldAttr(dependantFields[0].internalId, "value", err.street);
            updateFieldAttr(dependantFields[1].internalId, "value", err.city);
            updateFieldAttr(allDependenciesFilledFieldIds[0], "isValid", false);
            updateFieldAttr(allDependenciesFilledFieldIds[1], "isValid", false);
          });
      }
    });
  }, [randomNo, fieldList, inputRefList.current]);

  function updateFieldAttr(
    internalId: string,
    attr: string,
    val: string | boolean
  ) {
    // console.log("#2 - update field attr", internalId, val);
    const newList = [...fieldList];
    // const newList = [...fieldList.filter((el) => el.isFieldVisible !== false)];
    const elInList = getElAndIndexInDeepArrayByAttr(
      fieldList,
      "internalId",
      internalId
    );

    // console.log("@", elInList);

    type ObjectKey = keyof typeof elInList.element;

    const attrToFind = attr as ObjectKey;

    const a = newList[elInList.index];
    (a as any)[attrToFind] = val;

    newList.map((_) => {
      // console.log(_.labelText, fieldHasConditionalVisibilityAndItIsMatchCB(_));
      _.isFieldVisible = fieldHasConditionalVisibilityAndItIsMatchCB(_);
      return _;
    });

    if (typeof val === "string") {
      updateInputRefVal(internalId, val);
    }
    // console.log(newList);
    setFieldList(() => [...newList]);
  }

  function updateInputRefVal(internalId: string, val: string | number) {
    // console.log("#3 - updateInputRefVal", internalId, val);
    // console.log("internalId", internalId);
    // console.log(fieldId);
    const el = getElAndIndexInDeepArrayByAttr(
      fieldList,
      "internalId",
      internalId
    );
    // console.log(el);
    // console.log(
    //   "#3 - updateInputRefVal",
    //   fieldList[el.index].labelText,
    //   fieldList[el.index].value,
    //   inputRefList.current[el.index]
    // );

    // @TODO: Check values from API to match the validation rules
    // inputRefList.current[elIndex].value = val ? val : "";
    if (el) {
      // console.log(el.element.labelText, el.element.value, val);
      let refVal = val ? val : "";

      if (el.element.inputType === InputType.SELECT && !val) {
        // console.log(
        //   "#",
        //   el.element.internalId,
        //   el.element.labelText,
        //   el.element.value,
        //   val
        // );

        refVal = "Select";
      }
      // console.log(
      //   internalId,
      //   el,
      //   el.element.fieldId,
      //   val,
      //   "refVal:" + refVal
      //   //inputRefList.current.length
      // );
      inputRefList.current[el.index].value = refVal.toString();
    }
  }

  // console.log(wizardContext.formFields[24]);
  // console.log(wizardContext.formFields[25]);
  // console.log(wizardContext.formFields[26]);

  useEffect(() => {
    // console.log("UE fieldList - updating input refs values", fieldList);
    fieldList.map((el) => {
      // console.log("updating field", el.labelText, el.internalId, el.value);
      updateInputRefVal(el.internalId, el.value!);
    });
    // console.log("list updated", fieldList[9]);
  }, [fieldList]);

  const fieldHasConditionalVisibilityAndItIsMatchCB = useCallback(
    (field: IFormControl, list?: IFormControl[]) => {
      // console.log(fieldList, fieldList.length ? fieldList : list);
      // const _fieldList = fieldList.length ? fieldList : list!;
      const _fieldList = list ? list : fieldList;
      // const _fieldList = wizardContext.formFields;
      // console.log(_fieldList);
      // IF has conditional and its not an Array
      if (field.conditional && !Array.isArray(field.conditional)) {
        // console.log("conditional is not an array", field.labelText);
        const conditional = field.conditional as IFieldDataCondition;
        // Has no join field. This is valid only for not dateTwofields
        // console.log(field.conditional.joinField, conditional);
        if (field.conditional.joinField === undefined) {
          // console.log("#", field.labelText);
          const fieldToCheckVal = fieldList.filter(
            (el) => el.fieldId === conditional.dependsOn
          )[0];

          // console.log(
          //   // checkDependencyMatch(fieldToCheckVal, conditional),
          //   // field,
          //   fieldToCheckVal,
          //   conditional
          // );
          if (
            checkDependencyMatch(fieldToCheckVal, conditional) &&
            fieldToCheckVal.isValid !== false
          ) {
            // console.log("# field " + field.labelText + " will be visible");
            return true;
          }
          // console.log("##", field.labelText);
        }
        // If has joinField (gambiarra for month and year separated fields)
        else {
          // console.log("#", _fieldList, conditional.dependsOn);

          const field1ToCheck = _fieldList.filter(
            (el) => el.fieldId === conditional.dependsOn
          )[0];
          // const field1ToCheck = _fieldList.filter((el) => {
          //   // console.log(el);
          //   return el && el.fieldId === conditional.dependsOn;
          // })[0];
          const field2ToCheck = _fieldList.filter(
            (el) => el.fieldId === conditional.joinField
          )[0];
          // console.log("#");
          // console.log(field1ToCheck, field2ToCheck, field.conditional);
          // console.log(field1ToCheck, field2ToCheck);
          if (
            field1ToCheck &&
            field1ToCheck.value &&
            field1ToCheck.value.toString().toLowerCase() !== "select" &&
            field1ToCheck.isValid !== false &&
            field2ToCheck &&
            field2ToCheck.value &&
            field2ToCheck.value.toString().toLowerCase() !== "select" &&
            field2ToCheck.isValid !== false
          ) {
            // console.log(
            //   "#",
            //   field.labelText,
            //   checkDateDependency2FieldsMatch(
            //     field1ToCheck,
            //     field2ToCheck,
            //     field.conditional
            //   )
            // );
            return checkDateDependency2FieldsMatch(
              field1ToCheck,
              field2ToCheck,
              field.conditional
            );
          } else {
            // console.log("has no values yet");
            return false;
          }
        }
        // console.log("#", field.labelText);
        return false;
      } else if (
        field.conditional &&
        Array.isArray(field.conditional) &&
        field.conditional.length > 0
      ) {
        // console.log(field.labelText, field.conditional);
        const conditionalQty = field.conditional.length;
        const conditionalChecks: boolean[] = [];

        // console.log(wizardContext.formFields);
        // console.log(fieldList);
        // console.log(field.labelText);
        field.conditional.map((conditional) => {
          let fieldToCheckVal = _fieldList.filter(
            (el) =>
              el.fieldId === conditional.dependsOn &&
              el.isFieldVisible !== false
          )[0];

          // console.log(fieldToCheckVal);
          // Nothing has found on the current step, so search on the global field array (all steps [for previous])
          if (fieldToCheckVal === undefined) {
            fieldToCheckVal = wizardContext.formFields.filter(
              (el) =>
                el.fieldId === conditional.dependsOn &&
                el.isFieldVisible !== false
            )[0];
          }
          // console.log(field.labelText);
          // console.log(
          //   fieldToCheckVal.labelText,
          //   "value: " + fieldToCheckVal.value
          // );
          // console.log(fieldToCheckVal);
          // console.log(conditional);
          // console.log("#", field.fieldId, fieldToCheckVal.isValid);
          // console.log("#");
          if (
            checkDependencyMatch(fieldToCheckVal, conditional) &&
            fieldToCheckVal.isValid !== false
          ) {
            conditionalChecks.push(true);
          }
          // console.log("#", field.fieldId);
        });

        if (conditionalChecks.length === conditionalQty) {
          return true;
        }

        return false;
      }
      return true;
    },
    [fieldList, inputRefList, wizardContext.formFields]
  );

  const refUpdateHandler = useCallback(
    (internalId: string, val: string) => {
      // console.log("#1 - new ref val", internalId, val);
      updateFieldAttr(internalId, "value", val);
    },
    [fieldList, inputRefList]
  );

  useEffect(() => {
    // console.log("some field changed", lastInputChanged);
    if (lastInputChanged !== undefined) {
      if (addressTypes.includes(lastInputChanged.inputType)) {
        // console.log("something changed");
        checkAndSetAddressFields();
        // console.log("call checkAndSetAddressFields()", );
      }
      if (lastInputChanged.confirmationFieldId) {
        validateConfirmationField(lastInputChanged.fieldId);
      }
    }
  }, [randomNo, lastInputChanged]);

  const validateConfirmationField = (fieldIdToValidate: string) => {
    const currentField = getElAndIndexInDeepArrayByAttr(
      fieldList,
      "fieldId",
      fieldIdToValidate
    );

    if (currentField.element.confirmationFieldId) {
      const referencedField = getElAndIndexInDeepArrayByAttr(
        fieldList,
        "fieldId",
        currentField.element.confirmationFieldId!
      );

      if (currentField.element.value !== referencedField.element.value) {
        updateFieldAttr(currentField.element.internalId, "isValid", false);
      } else {
        updateFieldAttr(currentField.element.internalId, "isValid", true);
      }
    }
  };

  const validateIbanFieldByAPI = (fieldToValidate: string) => {
    const field = getElAndIndexInDeepArrayByAttr(
      fieldList,
      "fieldId",
      fieldToValidate
    );
    if (field.element.inputType === InputType.IBAN) {
      validateIBANApiCall(field.element.value!.toString())
        .then((r) => {
          // console.log(r);
          // if (r) {
          updateFieldAttr(field.element.internalId, "isValid", r);
          // } else {
          // updateFieldAttr(field.element.fieldId, "isValid", false);
          // }
        })
        .catch((er) => {
          // updateFieldAttr(field.element.fieldId, "isValid", false);
          return false;
        });
    }
  };

  function onInputChangeHandler(field: TFormField, val: string) {
    // console.log("onInputCHangeHandler", fieldList);
    // console.log(field.toggleText, val);
    clearHiddenFields();
    refUpdateHandler(field.internalId, val);
    setRandomNo(Math.random());
    checkNotMandatoryFieldIsValid(field);
    setLastInputChanged(getFieldById(field.fieldId));
    validateConfirmationField(field.fieldId);
    validateIbanFieldByAPI(field.fieldId);
    // checkAndValidateCustomDateValidationFields(field.fieldId);
    checkAndValidateCustomDateValidationFields(field.internalId);
    checkCallbackFunctions(field);
  }

  const checkNotMandatoryFieldIsValid = (field: TFormField) => {
    if (!field.isMandatory) {
      const isValid = !field.value
        ? true
        : checkFieldIsValid(field, field.value!.toString());
      // console.log(field.labelText, field, isValid);
      updateFieldAttr(field.internalId, "isValid", isValid);
    }
  };
  const clearHiddenFields = () => {
    // console.log("clearHiddenFields");
    fieldList.map((_) => {
      // console.log(_.labelText, _.isFieldVisible);
      if (
        !_.isFieldVisible &&
        ![InputType.HIDDEN, InputType.RADIO].includes(_.inputType)
      ) {
        updateFieldAttr(_.internalId, "value", "");
      }
    });
  };

  const checkCallbackFunctions = (field: TFormField) => {
    if (field.callbackFunction) {
      // console.log(field.value);
      // if (checkFieldCbTrigger(field)) {
      callFunctionByName(
        field.callbackFunction.name,
        field.callbackFunction.args,
        field
      );
      // }
    }
  };

  const dynamicFunctions = {
    copyDirector1FieldsFromOtherStep(args: any, field: IFormControl) {
      // console.log("function called", args);
      // console.log(wizardContext.formFields);
      // const contactFirstName = getElAndIndexInDeepArrayByAttr(
      //   wizardContext.formFields,
      //   "fieldId",
      //   "ContactPerson-FirstName"
      // ).element.value;
      const contactLastName = getElAndIndexInDeepArrayByAttr(
        wizardContext.formFields,
        "fieldId",
        "ContactPerson-LastName"
      ).element.value;
      const contactPrefix = getElAndIndexInDeepArrayByAttr(
        wizardContext.formFields,
        "fieldId",
        "ContactPerson-Prefix"
      ).element.value;
      // console.log(
      //   contactFirstName + " # " + contactLastName + " # " + contactPrefix
      // );

      // if (contactFirstName && contactLastName && contactPrefix) {
      // if (contactFirstName && contactLastName) {
      if (contactLastName) {
        // const director1FirstName = getElAndIndexInDeepArrayByAttr(
        //   wizardContext.formFields,
        //   "fieldId",
        //   "Directors-FirstName"
        // ).element;
        const director1LastName = getElAndIndexInDeepArrayByAttr(
          wizardContext.formFields,
          "fieldId",
          "Directors-LastName"
        ).element;
        const director1Prefix = getElAndIndexInDeepArrayByAttr(
          wizardContext.formFields,
          "fieldId",
          "Directors-Prefix"
        ).element;

        // console.log(field.value);
        if (field.value === 1) {
          // console.log("#");
          // updateFieldAttr(
          //   director1FirstName.internalId,
          //   "value",
          //   contactFirstName!.toString()
          // );
          updateFieldAttr(
            director1LastName.internalId,
            "value",
            contactLastName!.toString()
          );
          updateFieldAttr(
            director1Prefix.internalId,
            "value",
            contactPrefix ? contactPrefix.toString() : ""
          );
        } else {
          // To clear fields when mark no
          // console.log("##");
          // updateFieldAttr(director1FirstName.internalId, "value", "");
          updateFieldAttr(director1LastName.internalId, "value", "");
          updateFieldAttr(director1Prefix.internalId, "value", "");
        }
      } else {
        alert("You didn't filled the contact person yet");
      }

      // return;
    }
  };

  function checkFieldCbTrigger(field: TFormField): boolean {
    if (field.callbackFunction) {
      // check single values
      if (
        field.callbackFunction.triggerConditionValue == // @TODO: Check type in the future
        field.value
      ) {
        return true;
      }
    }

    return false;
  }
  function callFunctionByName(
    functionName: string,
    args: any,
    field: IFormControl
  ) {
    switch (functionName) {
      case "copyDirector1FieldsFromOtherStep":
        dynamicFunctions.copyDirector1FieldsFromOtherStep(args, field);
        break;

      default:
        console.error(`Function ${functionName} not found`);
        break;
    }
  }

  // const checkAndValidateCustomDateValidationFields = (fieldId: string) => {
  const checkAndValidateCustomDateValidationFields = (internalId: string) => {
    // console.log("#1");
    // const lastChangedField = getElAndIndexInDeepArrayByAttr(
    //   fieldList,
    //   "fieldId",
    //   fieldId
    // );
    const lastChangedField = getElAndIndexInDeepArrayByAttr(
      fieldList,
      "internalId",
      internalId
    );
    // console.log("#2");
    if (lastChangedField.element.customDateValidation) {
      // console.log("#3");
      const otherFieldToValidate =
        getElAndIndexInDeepArrayByAttr(
          fieldList,
          "internalId",
          lastChangedField.element.customDateValidation.otherFieldId
        ) ??
        getElAndIndexInDeepArrayByAttr(
          fieldList,
          "fieldId",
          lastChangedField.element.customDateValidation.otherFieldId
        );

      // console.log(lastChangedField.element, fieldList);

      // console.log(
      //   getElAndIndexInDeepArrayByAttr(
      //     fieldList,
      //     "fieldId",
      //     lastChangedField.element.customDateValidation.otherFieldId
      //   ),
      //   getElAndIndexInDeepArrayByAttr(
      //     fieldList,
      //     "internalId",
      //     lastChangedField.element.customDateValidation.otherFieldId
      //   )
      // );

      // console.log(
      //   lastChangedField.element.labelText,
      //   lastChangedField.element.value
      // );
      // console.log(otherFieldToValidate);
      // console.log(
      //   otherFieldToValidate.element.labelText,
      //   otherFieldToValidate.element.value
      // );

      const monthField = lastChangedField.element.customDateValidation.isMonth
        ? lastChangedField
        : otherFieldToValidate;
      const yearField = lastChangedField.element.customDateValidation.isMonth
        ? otherFieldToValidate
        : lastChangedField;

      // const currentDate = moment(condition.value, defaultDateFormat).unix();

      // alert(
      //   monthField.element.fieldId +
      //     " new val is: " +
      //     monthField.element.value +
      //     "\n" +
      //     yearField.element.fieldId +
      //     " new val is: " +
      //     yearField.element.value
      // );

      // let isValid = false;
      // if (monthField.element.value && yearField.element.value) {
      // console.log(monthField.element, yearField.element);
      const isValid = validate2DatesFields(
        monthField.element,
        yearField.element
      );

      // console.log(isValid, monthField.element, yearField.element);

      if (!isValid) {
        // console.log("set INvalid dates");
        updateFieldAttr(monthField.element.internalId, "isValid", false);
        updateFieldAttr(yearField.element.internalId, "isValid", false);
      } else {
        // console.log("set VALID dates");
        updateFieldAttr(monthField.element.internalId, "isValid", true);
        updateFieldAttr(yearField.element.internalId, "isValid", true);
      }
      // }
    }
  };

  function startedGroupingArea(group: string | undefined, elIndex: number) {
    if (group !== undefined) {
      const firstElIndex = (el: IFormControl) =>
        el.groupingArea?.title === group;
      const firstGroupElementIndex = fieldList.findIndex(firstElIndex);
      // console.log("a", firstGroupElementIndex, elIndex);
      if (firstGroupElementIndex === elIndex) {
        return true;
      }
    }
    return false;
  }

  // const [missingFieldList, setMissingFieldList] = useState<IFormControl[]>([]);
  const checkNextBtnEnabled = useCallback(() => {
    let isDisabled = true;
    // console.log(fieldList);
    const mandatoryFields = fieldList.filter((_) => {
      // console.log(_.labelText, _.isMandatory, _.isValid);
      return (
        (_.isMandatory === true && _.isFieldVisible === true) ||
        (_.isMandatory !== true &&
          _.isValid === false &&
          (_.value !== undefined || _.value !== ""))
      );
    });

    // console.log(mandatoryFields);

    const validationFieldArray: boolean[] = [];

    mandatoryFields.map((_) => {
      // console.log(_.labelText);
      // validationFieldArray.push(checkFieldValidation(_));
      // console.log(_.labelText, _.value, _.isValid);
      if (_.value && [undefined, true].includes(_.isValid)) {
        // if (_.isValid !== undefined && _.isValid === true) {
        // console.log(_.labelText, checkFieldIsValid(_, _.value.toString()));
        validationFieldArray.push(checkFieldIsValid(_, _.value.toString()));
        // }
      }
      // console.log(_);
    });
    isDisabled =
      mandatoryFields.length !== validationFieldArray.length ||
      validationFieldArray.includes(false);

    // console.log(validationFieldArray);

    return isDisabled;
  }, [fieldList, fieldHasConditionalVisibilityAndItIsMatchCB]);
  // }

  /**
   *
   * function to get missing fields to display at baloon when next button is disabled
   *
   * */
  const getMissingFields = useCallback(() => {
    const mandatoryFields = fieldList.filter(
      (_) =>
        _.isMandatory && _.isMandatory === true && _.isFieldVisible === true
    );
    const invalidFieldsArray: IFormControl[] = [];

    mandatoryFields.map((_) => {
      // This checks agains mandatory fields if has some value and if is it value, to be used to create a list for tooltip for disabled button
      if (
        _.value === undefined ||
        checkFieldIsValid(_, _.value.toString()) === false
      ) {
        invalidFieldsArray.push(_);
      }
    });
    // console.log("#", invalidFieldsArray);

    return invalidFieldsArray;
  }, [checkNextBtnEnabled]);

  function setAttrVisibilityHandler(field: TFormField, isVisible: boolean) {
    // console.log(field.fieldId + " should be visible: " + isVisible);
    const fieldListCopy = [...fieldList];

    // setFieldList(fieldListCopy);
  }

  return (
    <div className="substep">
      <div className="wizard-container container p-md-4">
        <div className="mb-4">
          <Stepper
            formType={props.data.formType}
            currentSubStepObj={props.data.currentSubStep}
          />
        </div>

        <h2 className="mb-2 mb-md-4 title-group">
          {/* {props.data.currentSubStep.groupTitle} */}
          <b
            dangerouslySetInnerHTML={{
              __html: props.data.currentSubStep.groupTitle
            }}
          ></b>
        </h2>
        <Form className="form">
          <div className="row">
            {fieldList.map((f, index) => {
              // console.log(
              //   "@",
              //   f.fieldId,
              //   !fieldHasConditionalVisibilityAndItIsMatchCB(f)
              // );
              return (
                <React.Fragment key={index}>
                  {startedGroupingArea(f.groupingArea?.title, index) &&
                    fieldHasConditionalVisibilityAndItIsMatchCB(f) && (
                      <div className="col-12 my-3">
                        <h2 className="h6">{f.groupingArea?.title}</h2>
                      </div>
                    )}
                  <FormControl
                    key={index}
                    data={f}
                    onChange={onInputChangeHandler}
                    checkFieldVisibility={
                      fieldHasConditionalVisibilityAndItIsMatchCB
                    }
                    setAttrVisibility={(isVisible) =>
                      setAttrVisibilityHandler(f, isVisible)
                    }
                    ref={(input: any) => (inputRefList.current[index] = input)}
                  />
                  {f.displayOneLine && <div className="col-md-6"></div>}
                </React.Fragment>
              );
            })}
          </div>

          <div className="row justify-content-between ">
            <div className="w-auto">
              <Button
                variant="secondary "
                className="w-100 w-md-auto"
                onClick={onClickPrev}
                // disabled={}
              >
                Terug
              </Button>
            </div>

            <OverlayTrigger
              overlay={
                getMissingFields().length > 0 ? (
                  <Tooltip id={"btn-next"}>
                    {getMissingFields().map((_, index) => {
                      return <li key={index}>{_.labelText}</li>;
                    })}
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <div className="text-end w-auto">
                <Button
                  variant="primary"
                  className="w-100 w-md-auto"
                  onClick={onClickNext}
                  disabled={checkNextBtnEnabled()}
                >
                  Ga verder <i className="bi bi-arrow-right"></i>
                </Button>
              </div>
            </OverlayTrigger>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AppForm;
