import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { TFormField } from "../Components/WizardCmp";
import {
  getAllB2BFormFields,
  getAllB2CFormFields,
  getB2BSubSteps,
  getB2CSubSteps
} from "../Store/WizardController";
import { useParams, useSearchParams } from "react-router-dom";
import { saveStepData } from "../Store/ApiCalls";
import { AppContext } from "./AppContext";
import { findElAndIndexInDeepArray, transformDataFromApi } from "../helpers";
import { IFormControl } from "../Components/FormControl";
import { InputType } from "../Types/InputType";
import { command } from "yargs";

export interface IWizardContext {
  id: number;
  title: string;
  description: string;
  status: boolean;
}
export type WizardContextType = {
  formFields: TFormField[];
  save: (substepApiName: string, fieldListToSave: TFormField[]) => boolean;
  // updateTodo: (id: number) => void;
};

export const WizardContext = createContext<WizardContextType>(null!);

// interface BaseLayoutProps {
//   children: ReactNode;
// }

// const WizardContextProvider: React.FC<BaseLayoutProps> = ({ children }) => {
const WizardContextProvider = (props: { children: ReactNode }) => {
  const appContext = useContext(AppContext);

  // const { formType } = useParams();
  const [formFields, setFormFields] = useState<TFormField[]>([]);
  const [formType, setFormType] = useState("b2c");
  const [searchParams] = useSearchParams(); // @OTOD: Remove this. Duplicate to AppContext

  const [formGuid, setFormGuid] = useState("");

  const [isLoadingData, setIsLoadingData] = useState(true);

  // console.log("here");

  useEffect(() => {
    setFormGuid(searchParams.get("guid")!);

    // console.log(searchParams.get("guid")!);
    // console.log(appContext);
    if (appContext.prefilledData.FormType) {
      setFormType(appContext.prefilledData.FormType.toLowerCase());
      handleFormDataFromAPI();
    } /*else {
      // console.log("aa");
      if (formType === "b2b") {
        setIsLoadingData(false);
        setFormFields(getAllB2BFormFields());
      } else {
        // setFormFields(getAllB2CFormFields());
        // getAllB2CFormFields().then((r: any) => {
        //   console.log("result", r);
        //   // setIsLoadingData(false);
        // });
        // console.log("@1");
        // console.log("@@2", getAllB2CFormFields());
        // setFormFields(getAllB2CFormFields());
      }
    }*/
  }, [appContext.themeProperties]);

  async function handleFormDataFromAPI() {
    const formData = appContext.prefilledData.Data;
    let localFormFields: TFormField[] = [];

    if (appContext.prefilledData.FormType.toLowerCase() === "b2b") {
      // console.log("#");
      localFormFields = await getAllB2BFormFields();
    } else {
      // console.log("#start from here");
      // localFormFields = getAllB2CFormFields();
      // console.log("##", getAllB2CFormFields());
      localFormFields = await getAllB2CFormFields();

      // localFormFields = [];
      // localFormFields = [];
      // console.log(localFormFields);
    }
    // console.log(localFormFields);
    setIsLoadingData(false);

    // console.log(formFields);
    // console.log(formData);
    if (formData !== undefined) {
      let locallyMatchFieldIdList: any = [];
      // let duplicateFieldsIterator = 0;

      // 1 - Iterate over group fields from API.
      Object.keys(formData).forEach(function (apiStepGroups, index) {
        const fieldIdSearchPattern = apiStepGroups + "-";

        const apiGroupFields = formData[apiStepGroups];

        // 2 - Iterate over subgroup fields gotten from API (1)
        Object.keys(apiGroupFields).forEach(function (fieldName, index2) {
          // console.log(fieldName, idx);
          const apiFieldValue = apiGroupFields[fieldName];

          locallyMatchFieldIdList = [];

          localFormFields.map((_, mapIndex) => {
            if (_["fieldId"] === fieldIdSearchPattern + fieldName) {
              locallyMatchFieldIdList.push(mapIndex);
            }
          });

          if (locallyMatchFieldIdList.length > 1) {
            // console.log(locallyMatchFieldIdList);
            // console.log("duplicates", locallyMatchFieldIdList);
            // duplicateFieldsIterator += locallyMatchFieldIdList.length;
            locallyMatchFieldIdList.map((_a: number) => {
              localFormFields[_a].value = apiFieldValue;
            });
          } else {
            // console.log(
            //   "single field: " + fieldName,
            //   "# index: " + locallyMatchFieldIdList[0],
            //   "# val:" + apiFieldValue
            // );
            if (locallyMatchFieldIdList[0] !== undefined) {
              localFormFields[locallyMatchFieldIdList[0]].value = apiFieldValue;
            }
          }

          // const elToUpdateIndex = findElAndIndexInDeepArray(
          //   localFormFields,
          //   "fieldId",
          //   fieldIdSearchPattern + fieldName
          // );
          // // console.log(elToUpdateIndex, fieldName, apiFieldValue); // Uncomment this line to get the fields is missing mapping on WizzardController.tsx. It will appear some undefined value on it
          // if (elToUpdateIndex) {
          //   localFormFields[elToUpdateIndex.index].value = apiFieldValue;
          // }
        });
      });
    }
    // console.log(localFormFields);
    setFormFields(localFormFields);
  }

  function handleFormDataToAPI(field: TFormField): string | number {
    let r;
    switch (field.inputType) {
      // case InputType.RADIO:
      // case InputType.SELECT:
      //   r = field.options!.indexOf(field.value!) + 1;
      //   break;
      // case InputType.SELECT_CUSTOM:
      //   // @TODO separate by coma
      //   console.log(field.value);
      //   // field.value.map;
      //   break;
      default:
        r = field.value!;
        break;
    }
    return r;
  }

  function saveStep(substepApiName: string, fieldList: TFormField[]): boolean {
    // console.log(substepApiName);

    // Updating local fields
    const formFieldsCopy = [...formFields];
    fieldList.map((i, index) => {
      const indexToUpdate = formFieldsCopy.findIndex(
        (e) => e.internalId === i.internalId
      );
      formFieldsCopy[indexToUpdate].value = i.value;
    });
    // console.log(fieldList);
    // console.log(formFieldsCopy);
    // console.log(formFields.filter((_) => _.isFieldVisible === true));
    setFormFields(formFields);

    // Creating a key-value pair to submit the step data;
    const fieldsToSend = fieldList
      .filter((_) => _.isFieldVisible !== false)
      .map((_) => {
        return {
          [_.fieldId.replace(substepApiName + "-", "")]: handleFormDataToAPI(_)
        };
      });

    // console.log(fieldsToSend);

    const isMarried = [2, 3, 4].includes(+formFieldsCopy[10].value!);

    const substepList =
      formType === "b2c" ? getB2CSubSteps() : getB2BSubSteps();

    const substepIndex = substepList.findIndex(
      (el) => el.apiName === substepApiName
    );

    // console.log("is marreid", isMarried);
    // console.log(substepList.length, substepIndex);

    let isFinished = false;

    if (formType === "b2c") {
      // console.log("#", isMarried, substepIndex);
      if (isMarried && substepIndex === 7) {
        // console.log("#", isMarried, substepIndex);
        isFinished = true;
      } else if (!isMarried && substepIndex === 6) {
        isFinished = true;
      }
    } else if (formType === "b2b") {
      if (substepIndex == 2) {
        isFinished = true;
      }
    }

    // console.log("is to Finish", isFinished);

    // const dataToSave = {};

    // console.log(fieldsToSend);
    const dataToSave = {
      Guid: formGuid,
      Step: substepIndex + 1,
      IsFinished: isFinished,
      Data: {
        [substepApiName]: Object.assign({}, ...fieldsToSend)
      }
    };
    // console.log(appContext);
    // console.log(dataToSave);
    // const stepObj = currentSubsteps.filter(_=>_);
    // console.log(stepObj);
    // const dataToSave: any = handleFormDataToAPI();
    setIsLoadingData(true);
    saveStepData(appContext.prefilledData.URLToSave, dataToSave)
      .then((r) => {
        // console.log(r);
      })
      .finally(() => {
        // console.log("finally");
        setIsLoadingData(false);
      });
    // setIsLoadingData(false);

    return false;
  }

  return (
    <WizardContext.Provider value={{ formFields: formFields, save: saveStep }}>
      {!isLoadingData && props.children}
    </WizardContext.Provider>
  );
};

export default WizardContextProvider;
