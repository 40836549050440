import { useCallback, useEffect, useMemo, useState } from "react";
import StepIcon from "../Assets/Images/step-icon.svg";
import {
  getB2BSteps,
  getB2BSubSteps,
  getB2CSteps,
  getB2CSubSteps
} from "../Store/WizardController";
import { IStepNavigation, TSubStep } from "./WizardCmp";

function Stepper(props: { formType: string; currentSubStepObj: TSubStep }) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const stepList = props.formType === "b2b" ? getB2BSteps() : getB2CSteps();

  // // @TODO: duplicated to wizard.tsx fix this function
  // function filterSubSteps(step: number) {
  //   return;
  // }

  // console.log("currentStep: ", props.currentStepObj);
  useEffect(() => {
    setCurrentStepIndex(props.currentSubStepObj.belongsToStep - 1);

    const subSteps =
      props.formType === "b2b" ? getB2BSubSteps() : getB2CSubSteps();

    // console.log(props.formType);

    const subStepList = subSteps.filter(
      (_step) => _step.belongsToStep === props.currentSubStepObj.belongsToStep
    );

    let indexOfCurrentStep = 1;

    subStepList.map((item, index) => {
      if (item.groupTitle === props.currentSubStepObj.groupTitle) {
        indexOfCurrentStep = index + 1;
      }
    });

    if (indexOfCurrentStep === 1) {
      setCurrentProgress(100 - 10);
    } else if (indexOfCurrentStep === subStepList.length) {
      setCurrentProgress(10);
    } else {
      setCurrentProgress(100 - (100 / subStepList.length) * indexOfCurrentStep);
    }
  }, [props.currentSubStepObj]);

  function getStatusClassName(index: number) {
    if (currentStepIndex > index) {
      return "done ";
    } else if (currentStepIndex === index) {
      return "active ";
    } else {
      return "";
    }
  }

  function getGaugeBar() {
    return (
      <div
        className="gauge-bar h-100 position-absolute w-100"
        style={{
          right: currentProgress + "%"
        }}
      >
        <img
          src={StepIcon}
          alt="@TODO Step here"
          className="position-absolute "
        />
      </div>
    );
  }

  return (
    <div className="stepper">
      <div className="steps-line d-flex justify-content-around ">
        {stepList.map((step, index) => {
          return (
            <div
              className={
                "step-item w-100 text-center " + getStatusClassName(index)
              }
              key={index}
            >
              <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
                {step}
              </span>
              <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
                {currentStepIndex === index && getGaugeBar()}
                <span className="step-number">
                  {currentStepIndex > index ? (
                    <i className="bi bi-check-circle"></i>
                  ) : (
                    index + 1
                  )}
                </span>
              </div>
            </div>
          );
        })}

        {/* <div className="step-item w-100 text-center done">
          <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
            Persoonlijke gegevens
          </span>
          <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
            <span className="step-number">Icon check</span>
          </div>
        </div>

        <div className="step-item w-100 text-center active">
          <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
            Beroepsgegevens/Werk
          </span>
          <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
            <div className="gauge-bar h-100 position-absolute w-100">
              <img
                src={StepIcon}
                alt="@TODO Step here"
                className="position-absolute "
              />
            </div>
            <span className="step-number fw-bold">2</span>
          </div>
        </div>

        <div className="step-item w-100 text-center">
          <span className="step--name d-none d-md-block mb-md-2 fw-semibold ">
            Financiële gegevens
          </span>
          <div className="step--content d-flex justify-content-center w-100 position-relative overflow-hidden">
            <span className="step-number fw-bold ">3</span>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Stepper;
