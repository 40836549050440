import { ReactNode, createContext, useEffect, useMemo, useState } from "react";
import { getAllRetailerData } from "../Store/ApiCalls";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../Components/Spinner";

export interface IAppContext {
  // id: number;
  // title: string;
  // description: string;
  // status: boolean;
}
export type AppContextType = {
  // themeProperties: [{ property: string; value: string }];
  themeProperties: any;
  prefilledData: any;
  // saveTodo: (todo: ITodo) => void;
  // updateTodo: (id: number) => void;
};

export const AppContext = createContext<AppContextType>(null!);

const AppContextProvider = (props: { children: ReactNode }) => {
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [themePropertyList, setThemePropertyList] = useState<any>();
  const [prefilledData, setPrefilledData] = useState([]);

  const [searchParams] = useSearchParams();

  useMemo(() => {
    const guid = searchParams.get("guid");
    if (guid) {
      // Do fetch theme;
      getAllRetailerData(
        searchParams.get("guid") ? searchParams.get("guid")! : ""
      ).then((r: any) => {
        // console.log(r);
        setThemePropertyList(r.RetailerStyling);
        setPrefilledData(r.Form);
        setIsLoadingData(false);
      });
    } else {
      setIsLoadingData(false);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        themeProperties: themePropertyList,
        prefilledData: prefilledData
      }}
    >
      {isLoadingData && <Spinner />}
      {!isLoadingData && searchParams.get("guid") && props.children}
      {!searchParams.get("guid") && <p>ID not provided</p>}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
