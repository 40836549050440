import { InputType } from "./InputType";

export interface IFieldDataCondition {
  dependsOn: string; // Reference to a (previous) FieldId
  joinField?: string; // Bad code created to match bad design/ux needs. Was prefered to use 2 select fields instead of a default HTML input type month field;
  value: (string | number) | (string | number)[];
  operator?: ConditionalOperator;
}

export interface IFieldDataOptions {
  label: string;
  value: number | string;
  order?: number;
}
export interface IFieldCountriesDataOptions extends IFieldDataOptions {
  IsEU: boolean;
}

export interface IFieldDataConfig {
  internalId: string;
  fieldId: string;
  belongsToStep: number | string;
  devName?: string;
  labelText: string;
  toggleText?: string;
  inputType: InputType;
  groupingArea?: {
    title: string;
  };
  inputPlaceholder?: string;
  options?: IFieldDataOptions[];
  value?: number | string;
  minLength?: number;
  maxLength?: number;
  displayOneLine?: boolean;
  isDisabled?: boolean;
  labelHint?: string;
  conditional?: IFieldDataCondition | IFieldDataCondition[];
  filledBy?: string[];
  fieldColumnsWidth?: number;
  isMandatory?: boolean;
  maxValue?: number;
  minValue?: number;
  isDecimal?: number; // To be filled when inputType is number
  isMultiSelect?: boolean;
  isValid?: boolean;
  isFieldVisible?: boolean;
  confirmationFieldId?: string;
  visualRules?: {
    hardInvisibility: boolean;
    // displayOneLine: boolean;
    // labelHint: string;
    // fieldColumnsWidth: number;
    // isDisabled: boolean;
    extraClassNames?: string;
    mask?: RegExp;
  };
  validationRegex?: string | RegExp;
  validationMessage?: string;
  validationNoWhiteSpaces?: boolean;
  validationNoNumbers?: boolean;
  validationNoSpecialChar?: boolean;
  validationOnlyNumbers?: boolean;
  customDateValidation?: {
    otherFieldId: string;
    isMonth: boolean;
    combinationMinValue: number;
    combinationMaxValue: number;
  };
  callbackFunction?: {
    // triggerConditionValue: (string | number) | (string | number)[];
    triggerConditionValue: string | number;
    name: string;
    args: any;
  };
}

export enum ConditionalOperator {
  LessThan = "<",
  LessOrEqualThan = "<=",
  GreatherThan = ">",
  GreatherOrEqualThan = ">=",
  Equal = "=",
  Different = "!=",
  InArray = "includes",
  NotInArray = "not-includes"
}
