import WizardCmp from "../Components/WizardCmp";
import WizardContextProvider from "../Contexts/WizardContext";

const WizardPage = () => {
  return (
    <WizardContextProvider>
      <div className="wizard-page">
        <WizardCmp />
      </div>
    </WizardContextProvider>
  );
};

export default WizardPage;
